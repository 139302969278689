import { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaMinus, FaPlus } from "react-icons/fa6";
import { LuMapPin } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AmenitiesModal from "../../components/ListYourVilla/AddPropertyDetails/Amenities";
import ImgUploadModal from "../../components/ListYourVilla/AddPropertyDetails/ImgUploadModal";
import PhoneInputSeparater from "../../components/PhoneInput/PhoneInput";
import { SetDefaultValue } from "../../redux/features/AddPropertyDetails/AddPropertyDetailsSlice";
import {
  AmenitiesData,
  TypeOfListingData,
  cityData,
  countrydata,
} from "./additionalData";
const AddPropertyDetails = () => {
  const navigate = useNavigate();

  const [AmenitiesModalShow, setAmenitiesModalShow] = useState(false);
  const [ShowImgUpload, setShowImgUpload] = useState(false);
  const [showCountryDropdown, setShowCountryDropDown] = useState(false);
  const [ShowTypeOfListing, setShowTypeOfListing] = useState(false);
  const [countryCode, setCountryCode] = useState({ code: "IN", name: "India" });
  const [TypeOfList, setTypeOfListing] = useState("Apartment");
  const [noOfGuests, SetNoOfGuests] = useState(0);
  const [noOfBeds, SetNoOfBeds] = useState(0);
  const [noOfBedrooms, SetNoOfBedrooms] = useState(0);
  const [choosenAmenities, setChoosenAmenities] = useState([]);
  const [PropertyDetails, setPropertyDetails] = useState(null);
  const [imgData, setImgData] = useState([]);
  const [phoneData, setPhoneData] = useState(PropertyDetails?.Phone || "");
  const [dropdowns, setDropdowns] = useState({
    typeCity: false,
    typeStates: false,
  });
  const [selectedValues, setSelectedValues] = useState({
    typeCity: "",
    typeStates: "",
  });
  // const [showCountryDropdown, setShowCountryDropDown] = useState(false);
  // const [countryCode, setCountryCode] = useState(null);
  const countryDropdownRef = useRef(null);
  const countryButtonRef = useRef(null);

  // const [showTypeOfListing, setShowTypeOfListing] = useState(false);
  // const [TypeOfList, setTypeOfListing] = useState('');
  const typeOfListingDropdownRef = useRef(null);
  const typeOfListingButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target) &&
        countryButtonRef.current &&
        !countryButtonRef.current.contains(event.target)
      ) {
        setShowCountryDropDown(false);
      }
      if (
        typeOfListingDropdownRef.current &&
        !typeOfListingDropdownRef.current.contains(event.target) &&
        typeOfListingButtonRef.current &&
        !typeOfListingButtonRef.current.contains(event.target)
      ) {
        setShowTypeOfListing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { value } = useSelector((state) => state.AddPropertyDetailsSlice);
  const dispatch = useDispatch();
  const handleGetData = (e) => {
    e.preventDefault();
    const ListingTitle = e.target?.title?.value;
    const SupplierName = e.target?.supplierName?.value;
    const Phone = phoneData?.phone;
    const CountryCode = countryCode;
    const TypeOfListing = TypeOfList;
    const PropertyDescription = e.target?.PropertyDescription?.value;
    const NumberOfBeds = noOfBeds;
    const NumberOfGuests = noOfGuests;
    const NumberOfBedrooms = noOfBedrooms;
    const Amenities = choosenAmenities;
    const ListingAddress = e.target?.ListingAddress?.value;
    const City = selectedValues.typeCity;
    const State = selectedValues.typeStates;
    const PinCode = e.target?.PinCode?.value;
    const Landmark = e.target?.Landmark?.value;
    const Images = imgData;
    const datas = {
      HotelName: ListingTitle,
      SupplierName,
      Phone,
      CountryCode,
      TypeOfListing,
      PropertyDescription,
      NumberOfBeds,
      NumberOfGuests,
      NumberOfBedrooms,
      Amenities,
      ListingAddress,
      City,
      State,
      PinCode,
      Landmark,
      PropertyDetailsImg: imgData,
    };
    if (SupplierName === "") {
      alert("Please enter supplier name");
    } else if (ListingAddress === "") {
      alert("Please enter listing address");
    } else if (PropertyDescription) {
      alert("Please enter property description");
    } else if (City === "") {
      alert("Please select city");
    } else if (State === "") {
      alert("Please select state");
    } else if (PinCode === "") {
      alert("Please enter pincode");
    }
    localStorage.setItem("PropertyDetails", JSON.stringify(datas));
    setPropertyDetails(datas);
    dispatch(SetDefaultValue(datas));
    console.log(PropertyDetails);
    console.log(value);
  };
  console.log(value);
  useEffect(() => {
    console.log(localStorage.getItem("PropertyDetails"));
    if (localStorage.getItem("PropertyDetails")) {
      setPropertyDetails(JSON.parse(localStorage.getItem("PropertyDetails")));
      setTypeOfListing(
        JSON.parse(localStorage.getItem("PropertyDetails"))?.TypeOfListing
      );
      SetNoOfGuests(
        JSON.parse(localStorage.getItem("PropertyDetails"))?.NumberOfGuests
      );
      SetNoOfBedrooms(
        JSON.parse(localStorage.getItem("PropertyDetails"))?.NumberOfBedrooms
      );
      SetNoOfBeds(
        JSON.parse(localStorage.getItem("PropertyDetails"))?.NumberOfBeds
      );
      setChoosenAmenities(
        JSON.parse(localStorage.getItem("PropertyDetails"))?.Amenities
      );
      setCountryCode(
        JSON.parse(localStorage.getItem("PropertyDetails"))?.CountryCode
      );
      setSelectedValues({
        typeCity: JSON.parse(localStorage.getItem("PropertyDetails"))?.City,
        typeStates: JSON.parse(localStorage.getItem("PropertyDetails"))?.State,
      });
      setPhoneData(JSON.parse(localStorage.getItem("PropertyDetails"))?.Phone);
    }
  }, []);
  const handleNavigate = () => {
    if (phoneData?.phone === undefined) {
      alert("Please enter your phone number");
    } else if (PropertyDetails !== null) {
      setTimeout(() => {
        navigate("/ListYourVilla/PriceDetails", {});
      }, 500);
    }
  };

  const toggleDropdown = (dropdownType) => {
    setDropdowns((prevDropdowns) => ({
      typeCity: dropdownType === "typeCity" ? !prevDropdowns.typeCity : false,
      typeStates:
        dropdownType === "typeStates" ? !prevDropdowns.typeStates : false,
    }));
  };
  const handleOptionSelect = (dropdownType, selectedValue) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [dropdownType]: selectedValue,
    }));
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdownType]: false,
    }));
  };
  const [title, setTitle] = useState(PropertyDetails?.HotelName || "");

  const handleChange = (event) => {
    const newValue = event.target.value;

    // Regular expression to check for special characters or numbers
    const isInvalid = /[^a-zA-Z\s]/.test(newValue);

    if (isInvalid) {
      alert("You cannot add special characters or numbers in names.");
    } else {
      setTitle(newValue);
    }
  };
  return (
    <form onSubmit={handleGetData} className="w-full min-w-[800px]  flex-grow">
      <div className="text-zinc-950  text-[32px] font-normal font-butler leading-[48px] tracking-tight">
        Add property details
      </div>
      <div className="grid grid-cols-2  mb-[220px] w-full overflow-hidden mt-12 gap-x-4">
        <div className=" w-full">
          <div className="w-full h-20 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Hotel Name
            </div>

            <input
              type="text"
              name="title"
              required
              defaultValue={PropertyDetails?.HotelName}
              onChange={handleChange}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Enter the title of your listing"
            />
          </div>
          <div className="w-full h-20 mt-6 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Supplier Name
            </div>

            <input
              type="text"
              name="supplierName"
              required
              onChange={handleChange}
              defaultValue={PropertyDetails?.SupplierName}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Enter the title of your listing"
            />
          </div>
          <div className="w-full h-20 flex-col justify-start items-start my-6 gap-2 inline-flex relative">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Type of listing
            </div>

            <div
              role="button"
              ref={typeOfListingButtonRef}
              onClick={() => setShowTypeOfListing(!ShowTypeOfListing)}
              type="text"
              className="self-stretch h-12 px-4 py-3 rounded border relative outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
            >
              {TypeOfList}
              <div className="absolute right-4 text-black">
                <FaChevronDown />
              </div>
            </div>
            {ShowTypeOfListing && (
              <div
                ref={typeOfListingDropdownRef}
                className="w-full absolute top-20 left-0 right-0 bg-white z-40  border max-h-[50vh] overflow-y-scroll rounded-lg"
              >
                {TypeOfListingData?.map((item) => (
                  <div
                    onClick={() => {
                      setTypeOfListing(item);
                      setShowTypeOfListing(false);
                    }}
                    role="button"
                    className="py-1 hover:bg-gray-200 p-4"
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className=" w-full">
            <div className="w-full h-full flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Property description
              </div>

              <textarea
                name="PropertyDescription"
                defaultValue={PropertyDetails?.PropertyDescription}
                required
                className="self-stretch h-[200px] px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal resize-none font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Enter the title of your listing"
              />
            </div>
          </div>
          <div className="w-full mt-6 h-40 flex-col justify-start items-start  gap-8 inline-flex">
            <div className="h-6 flex-col justify-start items-start gap-2.5 flex">
              <div className="h-6 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Capacity
                </div>
              </div>
            </div>
            <div className="h-[104px] flex-col justify-start items-start gap-4 flex">
              <div className="w-[477px] justify-start items-center gap-2 inline-flex">
                <div className="w-72 text-neutral-900 text-sm font-normal font-Lato">
                  No. of Guests
                </div>
                <div className="h-6 justify-center items-center flex">
                  <div
                    role="button"
                    onClick={() => {
                      if (noOfGuests > 0) {
                        SetNoOfGuests(noOfGuests - 1);
                      }
                    }}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaMinus />
                    </div>
                  </div>
                  <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                    {noOfGuests}
                  </div>
                  <div
                    role="button"
                    onClick={() => SetNoOfGuests(noOfGuests + 1)}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[477px] justify-start items-center gap-2 inline-flex">
                <div className="w-72 text-neutral-900 text-sm font-normal font-Lato">
                  No. of Bedrooms
                </div>
                <div className="h-6 justify-center items-center flex">
                  <div
                    role="button"
                    onClick={() => {
                      if (noOfBedrooms > 0) SetNoOfBedrooms(noOfBedrooms - 1);
                    }}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaMinus />
                    </div>
                  </div>
                  <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                    {noOfBedrooms}
                  </div>
                  <div
                    role="button"
                    onClick={() => SetNoOfBedrooms(noOfBedrooms + 1)}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[477px] justify-start items-center gap-2 inline-flex">
                <div className="w-72 text-neutral-900 text-sm font-normal font-Lato">
                  No. of Beds
                </div>
                <div className="h-6 justify-center items-center flex">
                  <div
                    role="button"
                    onClick={() => {
                      if (noOfBeds > 0) {
                        SetNoOfBeds(noOfBeds - 1);
                      }
                    }}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaMinus />
                    </div>
                  </div>
                  <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                    {noOfBeds}
                  </div>
                  <div
                    role="button"
                    onClick={() => SetNoOfBeds(noOfBeds + 1)}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="Amenities" className="mt-6">
            <div
              role="button"
              onClick={() => setAmenitiesModalShow(true)}
              className="w-[466px] h-16 flex-col justify-start items-start gap-4 inline-flex"
            >
              <div className="h-6 flex-col justify-start items-start gap-8 flex">
                <div className="w-[466px] justify-start items-start gap-2.5 inline-flex">
                  <div className="w-72 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                      Amenities
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-start items-center gap-3 inline-flex">
                <div className="w-5 text-blue-700 h-5 relative flex justify-center items-center">
                  <FaPlus />
                </div>
                <div className="text-blue-700 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add amenities
                </div>
              </div>
            </div>
          </div>
          {choosenAmenities.map((data, index) => {
            return <li>{data}</li>;
          })}
          <div id="AddImages" className="mt-6">
            <div className="w-[466px] h-16 flex-col justify-start items-start gap-4 inline-flex">
              <div className="h-6 flex-col justify-start items-start gap-8 flex">
                <div className="w-[466px] justify-start items-start gap-2.5 inline-flex">
                  <div className="w-72 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                      Upload Images
                    </div>
                  </div>
                </div>
              </div>
              <div
                role="button"
                onClick={() => setShowImgUpload(true)}
                className="justify-start items-center gap-3 inline-flex"
              >
                <div className="w-5 text-blue-700 h-5 relative flex justify-center items-center">
                  <FaPlus />
                </div>
                <div className="text-blue-700 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add images
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full">
          <div className="w-full  flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Phone
            </div>
            <PhoneInputSeparater
              VALUE={PropertyDetails?.Phone}
              NAME={"phone"}
              inputVal={phoneData}
              updateInputVal={setPhoneData}
              dvalue={phoneData}
            />

            {/* <input
              type="text"
              required
              name="phone"
              defaultValue={PropertyDetails?.Phone}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Enter the title of your listing"
            /> */}
          </div>
          <div
            // onMouseEnter={() => setShowCountryDropDown(true)}
            // onMouseLeave={() => setShowCountryDropDown(false)}
            className="w-full h-20 flex-col justify-start items-start relative my-6 gap-2 inline-flex"
          >
            <div
              role="button"
              className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight"
            >
              Country
            </div>

            <div
              type="text"
              role="button"
              ref={countryButtonRef}
              onClick={() => setShowCountryDropDown(!showCountryDropdown)}
              className="self-stretch h-12 px-4 py-3 rounded border relative outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
            >
              {countryCode?.name}-{countryCode?.code}
              <div className="absolute right-4 text-black">
                <FaChevronDown />
              </div>
            </div>
            {showCountryDropdown && (
              <div
                ref={countryDropdownRef}
                className="w-full absolute top-20 left-0 right-0 bg-white z-40  border max-h-[50vh] overflow-y-scroll rounded-lg"
              >
                {countrydata?.map((item) => (
                  <div
                    onClick={() => {
                      setCountryCode(item);
                      setShowCountryDropDown(false);
                    }}
                    role="button"
                    className="py-1 hover:bg-gray-200 p-4"
                  >
                    {item.name}-{item.code}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="w-full h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Listing Address
            </div>

            <input
              type="text"
              name="ListingAddress"
              required
              defaultValue={PropertyDetails?.ListingAddress}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Add your listing address"
            />
          </div>

          <div className="flex gap-x-3 relative w-full">
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                City
              </div>
              <div
                role="button"
                onClick={() => toggleDropdown("typeCity")}
                type="text"
                className="self-stretch h-12 px-4 py-3 rounded border relative outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              >
                {selectedValues.typeCity || "Select City"}
                <div className="absolute right-4 text-black">
                  <FaChevronDown />
                </div>
              </div>
              {dropdowns.typeCity && (
                <div className="w-[50%] my-1 absolute top-20 left-0 right-0 bg-white z-40  border max-h-[50vh] overflow-y-scroll rounded-lg">
                  {cityData?.map((item) => (
                    <div
                      onClick={() => handleOptionSelect("typeCity", item.name)}
                      role="button"
                      className="py-1 hover:bg-gray-200 p-4"
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )}

              {/* <input
                type="text"
                name="City"
                required
                defaultValue={PropertyDetails?.City}
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add your city"
              /> */}
            </div>
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                State
              </div>
              <div
                role="button"
                onClick={() => toggleDropdown("typeStates")}
                type="text"
                className="self-stretch h-12 px-4 py-3 rounded border relative outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              >
                {selectedValues.typeStates || "Select State"}
                <div className="absolute right-4 text-black">
                  <FaChevronDown />
                </div>
              </div>
              {dropdowns.typeStates && (
                <div className="w-[49%] my-1 absolute top-20 left-[51%] right-0 bg-white z-40  border max-h-[50vh] overflow-y-scroll rounded-lg">
                  {cityData?.map((item) => (
                    <div
                      onClick={() =>
                        handleOptionSelect("typeStates", item.state)
                      }
                      role="button"
                      className="py-1 hover:bg-gray-200 p-4"
                    >
                      {item.state}
                    </div>
                  ))}
                </div>
              )}

              {/* <input
                type="text"
                name="State"
                required
                defaultValue={PropertyDetails?.State}
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add your state"
              /> */}
            </div>
          </div>
          <div className="flex gap-x-3 relative w-full">
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                PIN Code
              </div>

              <input
                type="text"
                name="PinCode"
                required
                defaultValue={PropertyDetails?.PinCode}
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add your PIN"
                pattern="\d{6}"
                maxLength={6} // Limits input to 6 characters
                title="Please enter a 6-digit PIN code"
              />
            </div>
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Landmark (Optional)
              </div>

              <input
                type="text"
                defaultValue={PropertyDetails?.Landmark}
                name="Landmark"
                required
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add a landmark"
              />
            </div>
          </div>
          <div className=" w-full">
            <div className="w-full h-full flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Pin Location
              </div>

              <div className="w-full relative h-[313px] bg-blue-400 rounded">
                <div className="w-[151px] bottom-4 right-4 absolute h-11 px-6 py-4 bg-white rounded border border-blue-600 justify-center items-center gap-2 inline-flex">
                  <div className="text-blue-600 text-base font-semibold font-Lato text-nowrap leading-normal flex gap-x-2 items-center">
                    Pin location <LuMapPin />
                  </div>
                  <div className="w-4 h-4 relative" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 justify-end">
        <Link
          to="/ownerprofile/reservations"
          className="w-[106px] h-12 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
            Cancel
          </div>
        </Link>
        <button
          onClick={() => handleNavigate()}
          className="w-[178px] h-12 px-6 py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
            Add pricing details
          </div>
        </button>
      </div>

      {AmenitiesModalShow && (
        <AmenitiesModal
          AmenitiesData={AmenitiesData}
          choosenAmenities={choosenAmenities}
          setChoosenAmenities={setChoosenAmenities}
          setAmenitiesModalShow={setAmenitiesModalShow}
        />
      )}
      {ShowImgUpload && (
        <ImgUploadModal
          imgData={imgData}
          setImgData={setImgData}
          setShowImgUpload={setShowImgUpload}
        />
      )}
    </form>
  );
};

export default AddPropertyDetails;
