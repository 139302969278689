import React, { useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";

const EventSearchBar = (props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const checkInRef = useRef(null);
  const checkOutRef = useRef(null);

  return (
    <form className="min-w-[1250px] gap-[18px] rounded-2xl grid grid-cols-4 py-8 px-[88px] absolute -translate-x-[50%] left-[50%] -bottom-[25%] bg-white mx-auto shadow-md">
      {/* Dest field */}
      <div className="relative col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Enter Destination/Villa
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              props?.form[0](e.target.value);
              setDropdownVisible(true);
            }}
            value={props.formVal[0]}
            type="text"
            className="rounded outline-none w-full"
            onFocus={() => setDropdownVisible(true)}
            onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              props?.form[0]("");
            }}
          >
            <SlLocationPin className="w-5 h-5" />
          </button>
        </div>
        {isDropdownVisible && (
          <div className="absolute w-full bg-white border border-gray-300 rounded mt-1 max-h-40 overflow-y-auto">
            {props?.city?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  props.form[0](item.name);
                  setDropdownVisible(false);
                }}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Checkin field */}
      <div className="relative">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Check In
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            ref={checkInRef}
            onChange={(e) => {
              props?.form[1](e.target.value);
            }}
            type="date"
            className="rounded outline-none w-full"
            placeholder="Select Date"
          />
          <LuCalendarDays
            onClick={() => checkInRef.current?.showPicker()}
            className="m-auto absolute w-6 h-6 bg-white z-10 right-4"
          />
        </div>
      </div>
      {/* Checkout field */}
      <div className="relative">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Check Out
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            ref={checkOutRef}
            onChange={(e) => {
              props?.form[2](e.target.value);
            }}
            type="date"
            className="rounded outline-none w-full"
            placeholder="Select Date"
          />
          <LuCalendarDays
            onClick={() => checkOutRef.current?.showPicker()}
            className=" cursor-pointer m-auto absolute w-6 h-6 bg-white z-10 right-4"
          />
        </div>
      </div>
      {/* Event type field */}
      <div className="relative col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Type of event
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              props?.form[3](e.target.value);
            }}
            value={props?.formVal[1]}
            type="text"
            className="rounded outline-none w-full"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              props?.form[3]("");
            }}
          >
            <IoIosArrowDown />
          </button>
        </div>
      </div>
      {/* Guests field */}
      <div className="relative col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Guests
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              props?.form[4](Number(e.target.value));
            }}
            value={props?.formVal[2]}
            type="text"
            className="rounded outline-none w-full"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              props?.form[4]("");
            }}
          >
            <IoIosArrowDown />
          </button>
        </div>
      </div>
      {/* Search button */}
      <div className="col-span-4 flex justify-center items-center">
        <button
          onClick={props?.func}
          className="text-[#ffff] bg-[#08090A] font-bold leading-[16px] -tracking-[0.16px] min-w-[362px] rounded border-[1px] border-[#08090A] py-3 px-6"
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default EventSearchBar;
