import React, { useState } from "react";
import chevronup from "../../assets/svg/chevronup.webp";
const HouseRules = ({ houseRules }) => {
  console.log("house Rul", Array(houseRules));
  const [showHouseRules, setShowHouseRules] = useState(false);
  return (
    <div
      className="mt-[120px] px-5 lg:px-5 2xl:px-0"
      id="House Rules"
      style={{ scrollMarginTop: "80px" }}
    >
      {/* Section Heading */}
      <div onClick={() => setShowHouseRules(!showHouseRules)}>
        <h1 className="text-[36px] max-md:text-2xl font-[500] font-[Butler] flex items-center justify-between max-md:py-6">
          House Rules{" "}
          <img src={chevronup} className="max-md:block hidden w-6 h-6" alt="" />
        </h1>
        {/* divider */}
        <div className="w-full border border-[#CED4DA]"></div>
      </div>
      {/* main info */}
      <div
        className={`mt-[40px] ${showHouseRules ? "block" : "max-md:hidden"}`}
      >
        <ul className="list-disc flex flex-col gap-[12px] ml-5">
          <li className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
            {houseRules}
          </li>
        </ul>

        {Array(houseRules).length >= 3 && (
          <button className="w-[196px] h-12 px-6 py-4 rounded border border-zinc-950 mt-8 flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-zinc-950 text-base font-bold  leading-none">
              Read More
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default HouseRules;
