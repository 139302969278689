import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/pagination";
import { SwiperSlide } from "swiper/react";
import discountShape from "../../assets/svg/discount-shape.webp";
import "./CuponCard.css";
const CuponCard = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  const [cuponData] = useState([
    {
      discount: "10% off up to ₹1999",
      text: "Booking done from site",
    },
    {
      discount: "10% off up to ₹1999",
      text: "Booking done from site",
    },
    {
      discount: "10% off up to ₹1999",
      text: "Booking done from site",
    },
    {
      discount: "10% off up to ₹1999",
      text: "Booking done from site",
    },
  ]);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Coupon copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <div className="max-sm:w-[327px] w-full max-md:mx-auto px-6 h-[123px] mt-[40px] bg-[#F8F9FA] shadow-md p-[16px]">
      <Slider {...settings}>
        {cuponData?.map((item, index) => (
          <SwiperSlide
            key={index}
            className="max-xl:flex justify-center items-center"
            onClick={() => handleCopyToClipboard(item.discount)}
          >
            <div className="flex max-xl:justify-center items-center max-sm:justify-start">
              <img src={discountShape} alt="icon" />
              <p className="text-[20px] font-[600] ml-2">{item.discount}</p>
            </div>
            <p className="mt-[11px] max-xl:text-center max-sm:text-start">
              {item.text}
            </p>
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};

export default CuponCard;
