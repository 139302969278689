import { useEffect } from "react";
import AboutUsBanner from "../../components/AboutUs/Banner";
import WhyBookAVilla from "../../components/AboutUs/WhyBookAVilla";
import FutureGetawaysStepner from "../../components/FutureGetaways/FutureGetawaysStepner";
import HowItWork from "../../components/HowItWork/HowItWork";
import FAQs from "../../components/HowUkiyoWork/FAQs";
import HowItWorks from "../../components/Landinghomepage/HowItWorks";

const HowUkiyoWorks = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
   })
  return (
    <>
      {/* Banner */}
      <AboutUsBanner
        bannerHeading="How it Works?"
        bannerDescription="We go to extreme lengths, to handpick India’s hidden gems, away from the chaos of city life, to find villas, homestays, and estates, with breath-taking views, gorgeous aesthetics and the right home-like vibe, all within driving distance from your city."
      />
      {/* How it work section */}
      <HowItWork />
      {/* Why Us */}
      <WhyBookAVilla sectionTitle="Why Us?" />
      {/* Hwo it work section */}
      <HowItWorks />
      {/* FAQ */}
      <FAQs />
      {/* future getawasy stipper */}
      <div className="my-[144px] ">
        <FutureGetawaysStepner />
      </div>
    </>
  );
};

export default HowUkiyoWorks;
