import React from "react";
import { IoIosArrowDown } from "react-icons/io";

const FormDropDown = (props) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-[#08090A] font-semibold leading-6 tracking-[0.16px]">
        {props.name}
      </p>
      <select
        onChange={(e) => {
          props?.func(e.target.value);
        }}
        name=""
        id=""
        className="text-[#6D747A] h-12 p-2 rounded border-[1px] border-[#CED4DA] leading-6 tracking-[0.16px] "
      >
        <option disabled>{props.holder}</option>
        <option value="Opt1">Opt1</option>
        <option value="Opt2">Opt2</option>
      </select>
    </div>
  );
};

export default FormDropDown;
