import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInputSeparater from "../../PhoneInput/PhoneInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowDown } from "react-icons/io";

const RequestForm = () => {
  const [phone, setPhone] = useState("+91");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [interest, setInterest] = useState("Rent");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/HomeSolution") {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  }, [location.pathname]);

  const validateInputs = () => {
    let formErrors = {};

    if (!name) {
      formErrors.name = "Name is required.";
    } else if (!/^[a-zA-Z\s]{3,}$/.test(name)) {
      formErrors.name =
        "Name must be at least 3 characters long and contain only letters.";
    }

    if (!phone || phone.length < 12) {
      formErrors.phone = "A valid phone number is required.";
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }

    if (!email) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email address is invalid.";
    }

    if (!message) {
      formErrors.message = "Message is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const FormPosting = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (validateInputs()) {
      const formData = {
        name,
        phone,
        email,
        interest,
        message,
      };
      try {
        const fetching = await fetch(
          "https://api.ukiyostays.com/api/requests/addRequest",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        const data = await fetching.json();
        toast.success("Request sent successfully");
        e.target.reset();
        setErrors({});
      } catch (error) {
        toast.error("Something went wrong, try again");
        console.error("Error submitting the form:", error);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className={`relative mt-96 w-full sm:w-[575px] font-[butler] sm:absolute z-40 bg-white rounded-lg px-6 py-8 border md:right-[107px] ${showDropdown ? "top-[127px]":"top-[2050px]"} 2xl:right-[157px] border-gray-300 md:mb-20`}>
      <form onSubmit={FormPosting} className="flex flex-col space-y-6">
        <h1 className="text-black text-2xl font-semibold leading-9 tracking-tight">
          Send a Request
        </h1>
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Name
          </p>
          <input
            className="placeholder:text-[#434141] mt-2 w-full text-zinc-500 text-base font-normal px-4 py-3 focus:outline-none border rounded-lg leading-normal tracking-tight"
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.name}</p>
          )}
        </div>
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Phone
          </p>
          <div
            className={`phone-input-wrapper text-base border mt-2 rounded-md ${
              !isPhoneValid ? "border-red-500" : ""
            }`}
          >
            <PhoneInputSeparater
              Type="tel"
              PlaceHolder="Enter your phone number"
              setPhone={setPhone}
              VALUE={phone}
            />
          </div>
          {errors.phone && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.phone}</p>
          )}
        </div>
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Email
          </p>
          <input
            className="placeholder:text-[#434141] mt-2 w-full text-zinc-500 text-base font-normal px-4 py-3 focus:outline-none border rounded-lg leading-normal tracking-tight"
            placeholder="exampleperson@gmail.com"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.email}</p>
          )}
        </div>
        {showDropdown && (
          <div>
            <div className={`mb-4 relative`}>
              <label
                htmlFor="interest"
                className="block text-[#08090A] font-[600] flex justify-start"
              >
                Interested In
              </label>
              <input
                placeholder="Rent"
                type="text"
                name="interest"
                readOnly
                value={interest}
                id="interest"
                className={`mt-1 p-2 w-[100%] h-12 border rounded-md flex-1 cursor-pointer`}
                onClick={() => setDropdown(!dropdown)}
              />
              <div className="absolute items-center top-8 right-5">
                <IoIosArrowDown
                  className={`-mr-2 mt-2.5 cursor-pointer ml-2 h-5 w-5 font-medium leading-8 transition-transform duration-300 ${
                    dropdown ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {dropdown && (
                <div className="absolute top-20 w-[198px] h-[192px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <div>
                    {[
                      "Rent",
                      "Own",
                      "Partner as a Homeowner",
                      "Others",
                    ].map((option) => (
                      <p
                        key={option}
                        onClick={() => {
                          setInterest(option);
                          setDropdown(false);
                        }}
                        className="py-2 px-2 text-[#08090A] font-[400] hover:bg-[#F7F7F7] cursor-pointer"
                      >
                        {option}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Message
          </p>
          <textarea
            className="mt-2 placeholder:text-[#434141] w-full text-zinc-500 text-base font-normal px-4 h-[145px] py-3 focus:outline-none border rounded-lg leading-normal tracking-tight"
            placeholder="Leave us a message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {errors.message && (
            <p className="text-red-500 absolute z-50 text-sm">
              {errors.message}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="w-full h-12 bg-zinc-950 rounded text-white text-base font-bold flex justify-center items-center"
          disabled={isSubmitting}
        >
          Send Message
        </button>
      </form>
      <ToastContainer className=" mt-15 " />
    </div>
  );
};

export default RequestForm;
