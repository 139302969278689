import { useState } from "react";
import { FaAngleRight, FaLink } from "react-icons/fa6";
import { useSelector } from "react-redux";
import AdditionalChargesModal from "../../components/Ical/AdditionalChargedModal";
import BasePriceModal from "../../components/Ical/BasePriceModal";
import ConnectToCalender from "../../components/Ical/ConnectToCalender";
import CustomDiscountModal from "../../components/Ical/CustomDiscountModal";
import MonthlyDiscountModal from "../../components/Ical/MonthlyDiscountModal";
import MoreAvailiability from "../../components/Ical/MoreAvailiability";
import WeekendPriceModal from "../../components/Ical/WeekendPriceModal";
import WeeklyDiscountModal from "../../components/Ical/WeeklyDiscountModal";

const CalenderSideBar = () => {
  const Pricing = useSelector((state) => state.PricingOrAvailability.value);
  const [showBasePriceModal, setShowBasePriceModal] = useState(false);
  const [showWeekendPriceModal, setShowWeekendPriceModal] = useState(false);
  const [ShowWeeklyDiscountModal, setShowWeeklyDiscountModal] = useState(false);
  const [ShowMonthlyDiscountModal, setShowMonthlyDiscountModal] =
    useState(false);
  const [ShowCustomDiscountModal, setShowCustomDiscountModal] = useState(false);
  const [ShowAdditionalChargesModal, setShowAdditionalChargesModal] =
    useState(false);
  const [showAdvanceNotice, setShowAdvanceNotice] = useState(false);
  const AdvanceNoticeOptions = [
    "Same day",
    "At least 1 day",
    "At least 2 days",
    "At least 7 days",
  ];
  const [selectedAdvanceNotice, setSelectedAdvanceNotice] =
    useState("At least 1 day");
  const [showAvailabilityWindow, setShowAvailabilityWindow] = useState(false);
  const AvailabilityWindowOptions = [
    "12 months in advance",
    "9 months in advance",
    "6 months in advance",
    "3 months in advance",
  ];
  const [selectedAvailabilityWindow, setSelectedAvailabilityWindow] = useState(
    "12 months in advance"
  );
  const [ShowMoreAvailiability, setShowMoreAvailiability] = useState(false);
  const [showConnectToCalendar, setShowConnectToCalendar] = useState(false);
  return (
    <div className="w-[306px] pe-4 border-e-2 h-full pt-4">
      {Pricing === true ? (
        <>
          <div className="h-[30px]  items-center justify-between w-full flex">
            <div className="text-black text-xl font-normal font-Lato leading-[30px] tracking-tight">
              Base Price
            </div>
            <div className="text-black text-sm font-medium cursor-pointer font-Lato underline leading-[21px] tracking-tight">
              EDIT
            </div>
          </div>
          {/* per night price */}
          <div
            onClick={() => setShowBasePriceModal(true)}
            className="w-full h-[86px] p-4 bg-white my-4 rounded border border-[#ced4da] flex-col justify-start items-start inline-flex"
          >
            <div className="text-black text-2xl font-semibold font-Lato leading-9 tracking-tight">
              ₹1,299
            </div>
            <div className="text-[#6c747a] text-xs font-normal font-Lato leading-[18px]">
              per night
            </div>
          </div>
          {showBasePriceModal && (
            <BasePriceModal setShowBasePriceModal={setShowBasePriceModal} />
          )}
          {/* choose weekend price */}
          <div
            onClick={() => setShowWeekendPriceModal(true)}
            className="w-full mb-4 h-[53px] p-4 bg-white rounded border border-[#ced4da] cursor-pointer justify-start items-center gap-[87px] flex"
          >
            <div className="text-[#434141] text-sm font-normal font-Lato leading-[21px]">
              Choose a weekend price
            </div>
            <div className="w-[18px] h-[18px] relative">
              <FaAngleRight />
            </div>
          </div>
          {showWeekendPriceModal && (
            <WeekendPriceModal
              setShowWeekendPriceModal={setShowWeekendPriceModal}
            />
          )}
          <hr />
          <div className="text-black text-xl font-normal mt-4 font-Lato leading-[30px] tracking-tight">
            Discounts
          </div>
          {/* 10% for 7 nights or more */}
          <div
            onClick={() => setShowWeeklyDiscountModal(true)}
            className="w-full h-[86px] p-4 bg-white my-4 rounded border border-[#ced4da] flex-col justify-start items-start inline-flex"
          >
            <div className="text-black text-2xl font-semibold font-Lato leading-9 tracking-tight">
              10%
            </div>
            <div className="text-[#6c747a] text-xs font-normal font-Lato leading-[18px]">
              For 7 nights or more
            </div>
          </div>
          {ShowWeeklyDiscountModal && (
            <WeeklyDiscountModal
              setShowWeeklyDiscountModal={setShowWeeklyDiscountModal}
            />
          )}
          {/* 15% for 28 nights or more */}
          <div
            onClick={() => setShowMonthlyDiscountModal(true)}
            className="w-full h-[86px] p-4 bg-white mb-4 rounded border border-[#ced4da] flex-col justify-start items-start inline-flex"
          >
            <div className="text-black text-2xl font-semibold font-Lato leading-9 tracking-tight">
              15%
            </div>
            <div className="text-[#6c747a] text-xs font-normal font-Lato leading-[18px]">
              For 28 nights or more
            </div>
          </div>
          {ShowMonthlyDiscountModal && (
            <MonthlyDiscountModal
              setShowMonthlyDiscountModal={setShowMonthlyDiscountModal}
            />
          )}
          {/* custom discount */}
          <div
            onClick={() => setShowCustomDiscountModal(true)}
            className="w-full mb-4 h-[53px] p-4 bg-white rounded border border-[#ced4da] cursor-pointer justify-between items-center gap-[87px] flex"
          >
            <div className="text-[#434141] text-sm font-normal font-Lato leading-[21px]">
              Custom discounts
            </div>
            <div className="w-[18px] h-[18px] relative">
              <FaAngleRight />
            </div>
          </div>
          {ShowCustomDiscountModal && (
            <CustomDiscountModal
              setShowCustomDiscountModal={setShowCustomDiscountModal}
            />
          )}
          <hr />
          <div className="text-black text-xl font-normal font-Lato mt-4 leading-[30px] tracking-tight">
            Additional charges
          </div>
          <div
            onClick={() => setShowAdditionalChargesModal(true)}
            className="w-full my-4 h-[53px] p-4 bg-white rounded border border-[#ced4da] cursor-pointer justify-between items-center gap-[87px] flex"
          >
            <div className="text-[#434141] text-sm font-normal font-Lato leading-[21px]">
              Extra fees
            </div>
            <div className="w-[18px] h-[18px] relative">
              <FaAngleRight />
            </div>
          </div>
          {ShowAdditionalChargesModal && (
            <AdditionalChargesModal
              setShowAdditionalChargesModal={setShowAdditionalChargesModal}
            />
          )}
        </>
      ) : (
        <>
          <div className="text-black text-xl font-normal font-Lato leading-[30px] tracking-tight">
            Availability
          </div>
          {/* Advance notice */}
          <div
            role="button"
            onClick={() => setShowAdvanceNotice(!showAdvanceNotice)}
            className="w-full my-4 h-[71px] p-4 bg-white rounded border relative border-[#ced4da] justify-between items-center  flex"
          >
            <div className="flex-col justify-start items-start inline-flex">
              <div className="text-[#6c747a] text-xs font-normal font-Lato leading-[18px]">
                Advance notice
              </div>
              <div className="text-[#434141] text-sm font-medium font-Lato text-nowrap leading-[21px]">
                {selectedAdvanceNotice}
              </div>
            </div>
            <div className="w-[18px] h-[18px] relative  rotate-90">
              <FaAngleRight />
            </div>
            {showAdvanceNotice === true && (
              <div
                onClick={() => {
                  setShowAdvanceNotice(false);
                }}
                className="absolute bg-white flex-col flex justify-start -left-0 top-20 z-30 font-semibold w-full items-start rounded shadow px-6 py-3"
              >
                {AdvanceNoticeOptions?.map((item, index) => (
                  <div
                    role="button"
                    onClick={() => {
                      setShowAdvanceNotice(false);
                      setSelectedAdvanceNotice(item);
                    }}
                    key={index}
                    className="w-[283px] py-1.5 font-Lato justify-start items-center gap-2 inline-flex"
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Availability window */}
          <div
            onClick={() => setShowAvailabilityWindow(!showAvailabilityWindow)}
            className="w-full mb-4 h-[71px] p-4 bg-white rounded border border-[#ced4da] relative justify-between items-center flex"
          >
            <div className="flex-col justify-start items-start inline-flex">
              <div className="text-[#6c747a] text-xs font-normal font-Lato text-nowrap leading-[18px]">
                Availability window
              </div>
              <div className="text-[#434141] text-sm font-medium font-Lato text-nowrap leading-[21px]">
                {selectedAvailabilityWindow}
              </div>
            </div>
            <div className="w-[18px] h-[18px] relative  rotate-90">
              <FaAngleRight />
            </div>
            {showAvailabilityWindow === true && (
              <div
                onClick={() => {
                  setShowAdvanceNotice(false);
                }}
                className="absolute bg-white flex-col flex justify-start -left-0 top-20 z-30 font-semibold w-full items-start rounded shadow px-6 py-3"
              >
                {AvailabilityWindowOptions?.map((item, index) => (
                  <div
                    role="button"
                    onClick={() => {
                      setShowAdvanceNotice(false);
                      setSelectedAvailabilityWindow(item);
                    }}
                    key={index}
                    className="w-[283px] py-3 font-Lato justify-start items-center gap-2 inline-flex"
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* More availability options */}
          <div
            onClick={() => setShowMoreAvailiability(true)}
            className="w-full mb-4 h-[53px] p-4 bg-white rounded border border-[#ced4da] cursor-pointer justify-between items-center  flex"
          >
            <div className="text-[#434141] text-sm font-normal font-Lato leading-[21px]">
              More availability options
            </div>
            <div className="w-[18px] h-[18px] relative">
              <FaAngleRight />
            </div>
          </div>
          <hr />
          <div className="text-black text-xl font-normal font-Lato mt-4 leading-[30px] tracking-tight">
            Connect calendars
          </div>
          <div
            onClick={() => setShowConnectToCalendar(true)}
            className="w-full my-4 h-[53px] p-4 bg-white rounded border border-[#ced4da] cursor-pointer justify-between items-center  flex"
          >
            <div className="flex items-center gap-x-2">
              <FaLink className="text-[18p]" />
              <div className="text-[#434141] text-sm font-normal font-Lato leading-[21px]">
                Connect to another website
              </div>
            </div>
            <div className="w-[18px] h-[18px] relative">
              <FaAngleRight />
            </div>
          </div>
        </>
      )}
      {ShowMoreAvailiability && (
        <MoreAvailiability
          setShowMoreAvailiability={setShowMoreAvailiability}
        />
      )}
      {showConnectToCalendar && (
        <ConnectToCalender
          setShowConnectToCalendar={setShowConnectToCalendar}
        />
      )}
    </div>
  );
};

export default CalenderSideBar;
