import { useEffect, useRef, useState } from "react";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import scrollLeft from "../../../assets/svg/scroll-left.webp";
import scrollRight from "../../../assets/svg/scroll-right.webp";
import "../../LuxuryVillas/LuxuryVillas.css";

import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Card from "./Card";

const SimilarProperties = ({ similarHotelData }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  }, [similarHotelData]);

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  return (
    <>
      <div className="mb-[136px] px-5 lg:px-4">
        {/* similar navbar start */}
        <div className="flex items-center justify-between mt-[130px] ">
          <p className="text-[36px] max-md:text-2xl font-[500] font-[Butler]">
            View Similar Properties
          </p>
          {similarHotelData?.length > 4 && (
            <Link to="/allproperties">
              <a className="uppercase text-[#0F6DDC] text-[18px] font-[700]">
                View all
              </a>
            </Link>
          )}
        </div>
        {/* similar navbar end */}
        <div className="">
          {/* properties cards slide start */}
          <div className="ml-[11px] mt-[36px]">
            <div className="">
              <Swiper
                ref={swiperRef}
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay, A11y]}
                loop={false}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                  setIsBeginning(swiper.isBeginning);
                  setIsEnd(swiper.isEnd);
                }}
                breakpoints={{
                  1280: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  630: {
                    slidesPerView: 2,
                  },
                  368: {
                    slidesPerView: 1,
                  },
                }}
                spaceBetween={24}
              >
                {similarHotelData?.map((property) => (
                  <SwiperSlide className="w-full" key={property?.id}>
                    <div className="flex w-full justify-center">
                      <Card propertyData={property} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* indication for slide */}
            {similarHotelData?.length > 4 && (
              <>
                <div className="max-md:flex hidden items-center justify-end mt-5 max-sm:gap-2 gap-[16px]">
                  <button
                    className={`max-sm:w-6 max-lg:w-10 ${
                      isBeginning ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={swipePrev}
                    disabled={isBeginning}
                  >
                    <img src={scrollLeft} alt="scroll" />
                  </button>
                  <button
                    className={`max-sm:w-6 max-lg:w-10 ${
                      isEnd ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={swipeNext}
                    disabled={isEnd}
                  >
                    <img src={scrollRight} alt="scroll" />
                  </button>
                </div>
                <div className="flex max-md:hidden items-center justify-end mt-[32px] gap-[16px] ">
                  <button
                    className={
                      isBeginning ? "opacity-50 cursor-not-allowed" : ""
                    }
                    onClick={swipePrev}
                    disabled={isBeginning}
                  >
                    <img src={scrollLeft} alt="scroll" />
                  </button>
                  <button
                    className={isEnd ? "opacity-50 cursor-not-allowed" : ""}
                    onClick={swipeNext}
                    disabled={isEnd}
                  >
                    <img src={scrollRight} alt="scroll" />
                  </button>
                </div>
              </>
            )}
          </div>
          {/* properties cards slide end */}
        </div>
      </div>
    </>
  );
};

export default SimilarProperties;
