import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { countryCode, countryPhoneLengths } from "./PhoneData";

export default function PhoneInputSeparater({
  PlaceHolder,
  Type,
  VALUE,
  DISABLED,
  ID,
  NAME,
  updateInputVal,
  inputVal,
  setPhone,
  dvalue,
}) {
  const [country, setCountry] = useState("91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [codedropdown, setDropdown] = useState(false); // Default is false (dropdown hidden)

  useEffect(() => {
    if (VALUE) {
      const matchedCountry = Object.keys(countryPhoneLengths).find((code) =>
        VALUE.startsWith(code)
      );
      if (matchedCountry) {
        setCountry(matchedCountry.replace("+", ""));
        setPhoneNumber(VALUE.replace(matchedCountry, ""));
      }
    }
  }, [VALUE]);

  function validatePhoneNumber(phoneNumber) {
    const isNumeric = /^\d*$/.test(phoneNumber);
    const isValidLength = phoneNumber.length <= countryPhoneLengths[country];

    if (!isNumeric) {
      return "The phone number should contain only digits.";
    }
    if (!isValidLength) {
      return `The phone number should be up to ${countryPhoneLengths[country]} digits long.`;
    }
    return "";
  }

  function inputValue(e) {
    const inputValue = e.target.value;
    const phoneNumber = inputValue;
    const validationError = validatePhoneNumber(phoneNumber);

    if (validationError) {
      setErrorMessage(validationError);
    } else {
      setErrorMessage("");
      setPhoneNumber(phoneNumber);
      if (typeof setPhone === "function") {
        setPhone(country + phoneNumber);
      }
    }
    if (updateInputVal) {
      let allValue = { ...inputVal, [e.target.name]: country + phoneNumber };
      updateInputVal(allValue);
    }
  }
  return (
    <>
      <div className="relative border border-gray-300 rounded w-full h-12 flex justify-start pl-4 items-center gap-4 box-border">
        <p
          onClick={(e) => setDropdown(!codedropdown)}
          value={country}
          name="country"
          className="border-none cursor-pointer bg-transparent flex align-middle justify-between text-base w-[53.11px] h-[24px]"
        >
          +<span>{country}</span>
          <span
            className={`transition-transform duration-300 ${
              codedropdown ? "rotate-180" : "rotate-0"
            }`}
          >
            <svg
              className="w-4 h-4 mt-1 text-gray-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 9-7 7-7-7"
              />
            </svg>
          </span>
        </p>
        {codedropdown && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (codedropdown === false) {
                return;
              }
              if (codedropdown === true) {
                setDropdown(false);
              }
            }}
          >
            <div className="absolute top-full mt-1 left-0 w-[215px] border-[1px] rounded border-solid border-gray-100 bg-[#ffff] overflow-y-scroll max-h-[288px] z-10">
              {countryCode?.map((item) => (
                <p
                  key={item.dial_code}
                  onClick={() => {
                    setDropdown(false);
                    setCountry(item.dial_code);
                  }}
                  className="w-full flex justify-between h-12 pt-3 text-justify pr-6 pl-6 pb-3 cursor-pointer overflow-hidden"
                  value={item.dial_code}
                >
                  <span>+{item.dial_code}</span> {item.code}
                </p>
              ))}
            </div>
          </OutsideClickHandler>
        )}
        <div className="w-px h-5 bg-gray-900"></div>
        <input
          type={Type ? Type : "text"}
          // placeholder={PlaceHolder ? PlaceHolder : ""}
          placeholder="Enter your Phone Number"
          className="border-none w-52 pl-1 outline-none"
          value={phoneNumber}
          disabled={DISABLED ? DISABLED : false}
          id={ID ? ID : ""}
          name={NAME ? NAME : ""}
          onChange={inputValue}
        />
      </div>
      {/* {errorMessage && (
        <div className="text-red-500">{errorMessage}</div>
      )} */}
    </>
  );
}
