import React, { useEffect, useRef, useState } from "react";
import { FaHeart } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RectangleSlider4 from "../../assets/images/houseImg1.webp";
import RectangleSlider3 from "../../assets/images/houseImg2.webp";
import downchevron from "../../assets/svg/downArrow.svg";
import HeartImg from "../../assets/svg/HeartImg.webp";
import leftchevron from "../../assets/svg/leftchevron.webp";
import map from "../../assets/svg/location.webp";
import amentiesSearchIcon from "../../assets/svg/paw-print.webp";
import star from "../../assets/svg/start1.webp";
import whiteHeart from "../../assets/svg/whiteHeart.webp";
import wifi from "../../assets/svg/wifi1.webp";
import wifiGreen from "../../assets/svg/wifigreen.webp";
import { useAddWishlistMutation } from "../../redux/features/userDashboard/userDashboardApi";
import "./LuxuryVillas.css";
import LuxuryVillasSlider from "./LuxuryVillasSlider";

const VillaCard = ({
  searchData,
  token,
  city,
  SearchTag,
  checkIn,
  CheckOut,
  item,
}) => {
  const [show, setShow] = useState(5);
  const [apiRes, setAPiRes] = useState(searchData);
  const [wifiIcon, setwifiIcon] = useState(true);
  const [roomCounting, setRoomCounting] = useState(1);
  const [rooms, setRooms] = useState(false);
  const [Recommended, setRecommended] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [rangeValue, setRangeValue] = useState([minValue, maxValue]);
  const [addWishlist, { isSuccess }] = useAddWishlistMutation();
  const location = useLocation();
  const [addedTowishlist, setAddedTowishlist] = useState(false);
  const handleAddWishList = (id) => {
    console.log("object");
    addWishlist({ token, propertyId: id });
    if (isSuccess) {
      setAddedTowishlist(true);
    }
  };

  if (isSuccess) {
    alert("Property added to your wishlist successfully.");
  }

  console.log(item);
  const [LuxuryVillasCard, setLuxuryVillasCard] = useState([
    {
      heading: "Hotel Indigo Goa Downtown",
      heartIcon: HeartImg,
      whiteHeart: whiteHeart,
      mapIcon: map,
      mapIconText: "P.O. Goa University Bambolim, Goa,",
      entireVilla: "Entire Villa I  4-7 Guests  I  2 Bedroom  I  1 Pool ",
      amentiesSearchIcon: amentiesSearchIcon,
      amentiesSearchIconText: "Pets Allowed",
      wifiGreen: wifiGreen,
      wifiIcon: wifi,
      wifiIconText1: "Pool",
      wifiIconText2: "Wifi",
      wifiIconText3: "Breakfast Included",
      textAmenities: "10+ Amenities",
      starting: "starting from",
      Rs: "₹ 6,650",
      taxes: "+ taxes & fees/Per night",
      startIcon: star,
      reviews: "4.8 (385 reviews)",
      images: [
        {
          slideImages: RectangleSlider3,
        },
        {
          slideImages: RectangleSlider4,
        },
        {
          slideImages: RectangleSlider3,
        },
        {
          slideImages: RectangleSlider4,
        },
        {
          slideImages: RectangleSlider3,
        },
      ],
    },
  ]);
  const [sort, setSort] = useState({ label: "Recommended", key: "" });
  const [topFilter, setTopFilter] = useState({
    tag: [{ label: "", key: "", isSelected: false }],
    amenities: [{ label: "", key: "", isSelected: false }],
    bestRated: [{ label: "Best Rated", key: "best_rated", isSelected: false }],
    newHomes: [{ label: "New Homes", key: "new_homes", isSelected: false }],
  });
  const [checkboxData, setCheckboxData] = useState([
    "Villas",
    "Luxury Villas",
    "Homestays",
    "Bungalows",
    "Apartments",
    "Elite villas",
  ]);
  const sortData = [
    { label: "Price per night with taxes", key: "" },
    { label: "Most Popular", key: "" },
    { label: "Ukiyo Exclusive", key: "" },
    { label: "Recommended", key: "" },
    { label: "Price : Low to High", key: "price-low-to-high" },
    { label: "Price : High to Low", key: "price-high-to-low" },
    { label: " Newest First", key: "rating-high-to-low" },
    { label: "Is Featured", key: "true" },
  ];

  const handleToggle = () => {
    setRecommended(!Recommended);
  };

  const responseMapper = (response) => {
    const responseData = response;
    const HotelData = responseData?.data;
    const hotelDetails = HotelData?.availableHotels;
    const availableHotels = hotelDetails?.map((hotel) => {
      const id = hotel?.hotel?._id;
      const hotelName = hotel?.hotel?.name;
      const address = hotel?.hotel?.address + "  " + hotel?.hotel?.city;
      const amenities =
        hotel?.hotel?.amenities?.length > 10
          ? "10 + amenities"
          : hotel?.hotel?.amenities?.length + " amenities";
      const price = hotel?.hotel?.pricePerNight;
      const rating = hotel?.hotel?.rating;
      const reviews = hotel?.hotel?.reviews?.length;
      const images = hotel?.hotel?.imageUrls?.map((url) => {
        const image = url + "";
        const imageObj = { slideImages: image };
        return imageObj;
      });

      const hotelObj = {
        id: id,
        heading: hotelName,
        heartIcon: HeartImg,
        mapIcon: map,
        mapIconText: address,
        entireVilla:
          "Entire Villa      I  4-7 Guests  I  2 Bedroom  I  1 Pool ",
        amentiesSearchIcon: amenities,
        amentiesSearchIconText: "Pets Allowed",
        wifiGreen: wifiGreen,
        wifiIcon: wifi,
        wifiIconText1: "Pool",
        wifiIconText2: "Wifi",
        wifiIconText3: "Breakfast Included",
        textAmenities: amenities + " Amenities",
        starting: "starting from",
        Rs: "₹ " + price,
        taxes: "+ taxes & fees/Per night",
        startIcon: star,
        reviews: `${rating} ( ${reviews} reviews)`,
        images: images,
      };
      return hotelObj;
    });
    console.log("kjdnvjkvnjffv: ", availableHotels);

    setLuxuryVillasCard(availableHotels);
  };

  const handleDateFormat = (date) => {
    let day = date?.getDate();
    let month = date?.getMonth();
    let year = date?.getFullYear();
    let formattedDate =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;
    return formattedDate;
  };

  const filterData = async () => {
    const bestRated = topFilter.bestRated[0].isSelected
      ? "&bestRated=" + topFilter.bestRated[0].isSelected
      : "";
    const newHomes = topFilter.newHomes[0].isSelected
      ? "&newHomes=" + topFilter.newHomes[0].isSelected
      : "";
    const minPrice = rangeValue[0] > 0 ? "&minPrice=" + rangeValue[0] : "";
    const maxPrice = rangeValue[1] > 0 ? "&maxPrice=" + rangeValue[1] : "";
    const hotelType = checkboxData
      ?.filter((item) => item.isSelected)
      ?.map((item) => {
        return item.label;
      })
      .join(",");
    const tag = topFilter.tag
      ?.filter((item) => item.isSelected)
      ?.map((item) => {
        return item.label;
      })
      .join(",");
    const amenities = topFilter.amenities
      ?.filter((item) => item.isSelected)
      ?.map((item) => {
        return item.label;
      })
      .join(",");
    const hotelTypeEP = hotelType?.length > 0 ? "&hotelType=" + hotelType : "";
    const tagEP = tag?.length > 0 ? "&tags=" + tag : "";
    const amenitiesEP = amenities?.length > 0 ? "&amenities=" + amenities : "";
    const checkInType = checkIn?.length > 0 && handleDateFormat(checkIn);
    const CheckOutType = CheckOut?.length > 0 && handleDateFormat(CheckOut);

    console.log("sort by:: ", sort);
    if (
      location?.state?.hotelType &&
      !SearchTag &&
      location?.state?.from === "/typeofstays"
    ) {
      console.log(location);
      const data = await fetch(
        `https://api.ukiyostays.com/api/hotels/search?city=${city}&hotelType=${location?.state?.hotelType}&checkIn=${checkInType}&checkOut=${CheckOutType}&noOfRooms=${roomCounting}&guests=&sortBy=${sort.key}${minPrice}${maxPrice}${hotelTypeEP}${bestRated}${newHomes}${tagEP}${amenitiesEP}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await data.json();
      console.log("response data afterr sortt : ", responseData);
      responseMapper(responseData);
      return responseData?.data;
    } else if (
      SearchTag === "Luxury" &&
      location?.state?.from === "/luxuryproperties"
    ) {
      console.log(SearchTag);
      const data = await fetch(
        `https://api.ukiyostays.com/api/hotels/search?tags=Luxury&city=${city}&checkIn=${checkInType}&checkOut=${CheckOutType}&noOfRooms=${roomCounting}&guests=&sortBy=${sort.key}${minPrice}${maxPrice}${hotelTypeEP}${bestRated}${newHomes}${tagEP}${amenitiesEP}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await data.json();
      console.log("response data afterr sortt : ", responseData);
      responseMapper(responseData);
      return responseData?.data;
    } else if (!location?.state?.from) {
      const data = await fetch(
        `https://api.ukiyostays.com/api/hotels/search?city=${city}&checkIn=${checkInType}&checkOut=${CheckOutType}&noOfRooms=${roomCounting}&guests=&sortBy=${sort.key}${minPrice}${maxPrice}${hotelTypeEP}${bestRated}${newHomes}${tagEP}${amenitiesEP}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await data.json();
      console.log("response data afterr sortt : ", responseData);
      responseMapper(responseData);
      return responseData?.data;
    }
  };

  const getInitialData = async () => {
    const HotelData = await filterData();
    const minPrice = HotelData?.minPricePerNight;
    const maxPrice = HotelData?.maxPricePerNight;
    const filterTags = HotelData?.tags?.map((tag) => {
      return { label: tag, key: tag, isSelected: false };
    });
    const filterAminities = HotelData?.amenities?.map((item) => {
      return { label: item, key: item, isSelected: false };
    });
    const hotelTypes = HotelData?.hotelTypes?.map((item) => {
      return { label: item, key: item, isSelected: false };
    });

    setMinValue(0);
    setMaxValue(maxPrice);
    setRangeValue([0, maxPrice]);
    setCheckboxData(hotelTypes);
    setTopFilter((prev) => {
      return { ...prev, tag: filterTags, amenities: filterAminities };
    });
  };

  useEffect(() => {
    console.log(SearchTag);
    responseMapper(searchData);
    filterData();
  }, [searchData]);

  useEffect(() => {
    getInitialData();
    console.log(location);
    // responseMapper(searchData);
  }, []);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    const newMinValue = minValue + newValue;
    const newMaxValue = maxValue + newValue;
    setRangeValue([newMinValue, newMaxValue]);
  };
  const ref = useRef(null);

  const onclickClose = () => {
    setRecommended(false);
  };
  const [showTopFilters, setshowTopFilters] = useState(false);
  const [showRoomCount, setshowRoomCount] = useState(false);
  const [showSortBy, setSortBy] = useState(false);
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [typeofStay, setTypeofStay] = useState(false);
  const navigate = useNavigate();

  /** HANDLER FUNCTION - SHOW ALL PROEPRTIES */
  const handleShowAll = () => {
    setShow(LuxuryVillasCard?.length);
  };
  const [selectedRoom, setSelectedRoom] = useState(1);

  return (
    <>
      <div
        // onClick={() => navigate(`/propertyDetails/${item.id}`)}
        className="w-full"
      >
        <div className="shadow-lg mt-8 mr-0 md:rounded-lg xl:max-w-[866px] min-h-[320px]">
          <div className="flex max-md:relative w-full flex-col md:flex-row">
            <div className="md:w-72 w-full h-full object-cover">
              <LuxuryVillasSlider data={item.images} />
            </div>
            <div className="md:w-[614px]  m-4">
              <div className="flex justify-between">
                <h1 className="mb-1 max-md:text-xl text-black  font-[400] font-[Butler] text-2xl max-md:flex max-md:justify-between w-full max-md:items-center">
                  {item.heading}{" "}
                  <div className="hidden md:flex  max-md:text-sm font-normal font-Lato items-center justify-center">
                    <img
                      src={item.startIcon}
                      alt="startIcon"
                      className="mr-2  w-5 h-5"
                    />{" "}
                    <span>{item.reviews.split("reviews").join("")}</span>
                  </div>
                </h1>
                <p
                  onClick={() => handleAddWishList(item?.id)}
                  className="max-md:absolute top-4 max-md:z-30 right-4 cursor-pointer "
                >
                  {addedTowishlist ? (
                    <>
                      <FaHeart className="text-xl text-red-600" />
                    </>
                  ) : (
                    <img className="" src={item.heartIcon} alt="heartIcon" />
                  )}
                </p>
              </div>
              <span className="inline-flex text-[16px] font-[500] font-Lato max-sm:text-sm text-lg text-[#434141]">
                <img
                  src={item.mapIcon}
                  alt="mapIcon"
                  className="mr-2 max-md:w-[19px] max-md:h-[19px]"
                />{" "}
                {item.mapIconText}
              </span>
              <p className="tracking-wide font-Lato text-[#434141] max-md:text-sm my-2 lg:text-lg leading-6 font-[400]">
                {item.entireVilla}
              </p>
              <hr />
              <div className="flex  md:gap-20 md:m-2 font-Lato max-md:mt-3 text-lg text-[#6D747A] ">
                <span className="flex gap-x-1 items-center font-[400] max-md:text-sm ">
                  <img
                    src={amentiesSearchIcon}
                    alt="petsIcon"
                    className=" stroke-zinc-500  text-[#6D747A] h-6 w-6"
                  />
                  {item.amentiesSearchIconText}
                </span>

                <span className="flex items-center max-md:text-sm ml-7">
                  <img
                    src={wifi}
                    alt="wifiIcon"
                    className="mr-1 md:mr-2 text-[#6D747A]"
                  />{" "}
                  {item.wifiIconText1}
                </span>
                <button className="w-[79px] h-[26px] px-2 py-1 rounded border border-gray-300 absolute md:hidden right-2 justify-center items-center gap-2.5 inline-flex">
                  <div className="text-zinc-950 text-xs font-normal leading-[18px] tracking-tight">
                    Exceptional
                  </div>
                </button>
              </div>
              <div className="flex max-md:justify-normal font-Lato max-md:gap-x-6 justify-between max-md:m-0 max-md:my-2 m-2 text-lg text-[#6D747A]">
                <span className="flex items-center">
                  <img
                    src={item.wifiIcon}
                    alt="wifiIcon"
                    className="mr-2 w-6 h-6"
                  />{" "}
                  {item.wifiIconText2}
                </span>
                <span className="ml-20">
                  {" "}
                  {item.textAmenities.split("amenities").join("")}
                </span>
                <span className="text-[#434141] font-[400] text-[16px] max-md:hidden">
                  {" "}
                  {item.starting}
                </span>
              </div>
              <div className="flex justify-between max-md:m-0 font-Lato m-2 max-md:text-sm leading-6">
                <span className="flex justify-center text-green-600">
                  <img
                    src={item.wifiGreen}
                    alt="wifiIcon"
                    className={`mr-2 `}
                  />
                  {item.wifiIconText3}
                </span>

                <h4 className="font-[700] hidden md:block text-[#434141]">
                  {item.Rs}
                </h4>
              </div>
              <div className="mt-6 md:hidden font-Lato flex items-center justify-between">
                {" "}
                <div>
                  <span className="text-[#434141] max-md:text-sm">
                    {" "}
                    {item.starting}
                  </span>
                  <h4 className="font-[700] text-[#434141]">{item.Rs}</h4>
                  <p className="flex max-md:justify-start max-md:text-sm justify-end text-[#434141] text-lg">
                    {item.taxes}
                  </p>
                </div>
                <Link
                  to={`/propertyDetails/${item?._id}`}
                  className=" items-center justify-center "
                >
                  <button className="flex  px-6 py-3 items-center border border-gray-300 bg-black text-white rounded font-[600]">
                    View Details
                    <img
                      src={leftchevron}
                      alt="leftchevron"
                      className="ml-2 mt-1 w-[10px] h-[16px] text-white "
                    />
                  </button>
                </Link>
              </div>
              <p className=" hidden md:flex max-md:justify-start max-md:text-sm justify-end text-[#434141] text-lg">
                {item.taxes}
              </p>
              <div className="flex justify-between items-center mt-2  text-[#08090A]">
                <span className="md:flex hidden  mt-2 ml-3 items-center cursor-default">
                  <img
                    src={item.startIcon}
                    alt="startIcon"
                    className="mr-2 mt-0.5 w-5 h-5"
                  />{" "}
                  <span>{item.reviews}</span>{" "}
                  <button className="w-[79px] ms-3 h-[26px] px-2 py-1 rounded cursor-default border border-gray-300 justify-center items-center gap-2.5 inline-flex">
                    <div className="text-zinc-950 text-xs font-normal  leading-[18px] tracking-tight">
                      Exceptional
                    </div>
                  </button>
                </span>
                <div className="flex flex-col gap-2 justify-center items-center">
                  <div className="hidden relative md:block items-center justify-center ">
                    <button
                      onClick={() => {
                        setRooms(!rooms);
                      }}
                      className="flex  px-7 py-3 items-center border-[1px] border-[#08090A] rounded font-[600]"
                    >
                      For {selectedRoom} Room
                      <img
                        src={downchevron}
                        alt="leftchevron"
                        className="ml-2 mt-1 w-[10px] h-[16px] text-white "
                      />
                    </button>
                    <div
                      className={
                        rooms
                          ? "py-3 px-6 flex flex-col gap-3 shadow-xl absolute top-[100%] bg-white z-10 left-0"
                          : "hidden"
                      }
                    >
                      <p>Min. 1 Room</p>
                      <p>2 Rooms</p>
                      <p>2 Rooms</p>
                      <p>4 (All Rooms)</p>
                    </div>
                  </div>
                  <Link
                    to={`/propertyDetails/${item?.id}`}
                    className="hidden md:block items-center justify-center "
                  >
                    <button className="flex  px-6 py-3 items-center border border-gray-300 bg-black text-white rounded font-[600]">
                      View Details
                      <img
                        src={leftchevron}
                        alt="leftchevron"
                        className="ml-2 mt-1 w-[10px] h-[16px] text-white "
                      />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 block max-lg:hidden shadow-xl w-full text-[#08090A] font-[400] font-Lato text-base xl:max-w-[866px] ">
          Feature in multiple publications, this is a heritage villa designed
          using art deco architecture. This pool villa is spread across 2 acres
          of private forest. TT table and large swing on the verandah, terraced
          gardens lined with fruit tress.{" "}
        </div>
      </div>
    </>
  );
};

export default VillaCard;
