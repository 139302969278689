import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PriceDetails = () => {
  const location = useLocation();
  console.log(location?.state?.PropertyDetailsImg);

  const navigate = useNavigate();

  const [baseListingPrice, setBaseListingPrice] = useState(1299);
  const [WeekendPrice, setWeekendPrice] = useState(1299);
  const [CleaningFee, setCleaningFee] = useState(0);
  const [ExtraGuestFee, setExtraGuestFee] = useState(0);
  const [CustomFee, setCustomFee] = useState(0);
  const [WeeklyDiscount, setWeeklyDiscount] = useState(10);
  const [MonthlyDiscount, setMonthlyDiscount] = useState(15);
  const [CustomDiscount, setCustomDiscount] = useState(10);
  const [securityDeposit, setSecurityDeposit] = useState(1100);
  const total =
    JSON.parse(
      baseListingPrice === WeekendPrice
        ? baseListingPrice
        : baseListingPrice > WeekendPrice
        ? WeekendPrice
        : baseListingPrice
    ) +
    JSON.parse(CleaningFee) +
    JSON.parse(ExtraGuestFee) +
    JSON.parse(CustomFee);
  const totalMinue =
    (JSON.parse(baseListingPrice) *
      (JSON.parse(WeeklyDiscount) +
        JSON.parse(MonthlyDiscount) +
        JSON.parse(CustomDiscount))) /
    100;
  const [pricingData, setPricingData] = useState({});
  const HandleSaveData = () => {
    console.log(pricingData);
    localStorage.setItem("pricingDetails", JSON.stringify(pricingData));
  };

  const handleNavigate = () => {
    HandleSaveData();

    setTimeout(() => {
      navigate("/ListYourVilla/AddMoreOptions", {
        state: { ProperyDetailsImg: location?.state?.PropertyDetailsImg },
      });
    }, 100);
  };
  useEffect(() => {
    console.log(localStorage.getItem("pricingDetails"));
    if (localStorage.getItem("pricingDetails")) {
      setPricingData(JSON.parse(localStorage.getItem("pricingDetails")));
    } else {
      setPricingData({
        baseListingPrice,
        WeekendPrice,
        CleaningFee,
        ExtraGuestFee,
        CustomFee,
        WeeklyDiscount,
        MonthlyDiscount,
        CustomDiscount,
        total,
        securityDeposit,
        totalDiscount: totalMinue,
        totalAfterDiscount: total - totalMinue,
      });
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[65vw] mx-auto ">
      {/* title */}
      <div className="w-[257px] mb-12 h-12 flex-col justify-start items-start gap-10 inline-flex">
        <div className="justify-start items-center gap-[670px] inline-flex">
          <div className="h-12 justify-start items-start gap-[702px] flex">
            <div className="justify-center items-center gap-4 flex">
              <div className="text-zinc-950 text-[32px] font-normal font-butler leading-[48px] tracking-tight">
                Pricing details
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="gridDevide" className="flex gap-x-6 w-full h-full ">
        <div className="w-[491px] h-full ">
          <div>
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Set a base listing price
              </div>
            </div>
            <div className="w-full mt-2.5 h-[113px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex">
              <input
                onChange={(e) => setBaseListingPrice(Number(e.target.value))}
                defaultValue={pricingData?.baseListingPrice}
                className="text-black text-2xl font-semibold font-Lato leading-9 outline-none tracking-tight"
                type="text"
                placeholder="₹1,299"
              />
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per night
              </div>
              <div className="justify-start items-center inline-flex">
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  Guest price before taxes ₹1,482
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Set a weekend price
              </div>
            </div>
            <div className="w-full mt-2.5 h-[113px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex">
              <input
                onChange={(e) => setWeekendPrice(Number(e.target.value))}
                defaultValue={pricingData?.WeekendPrice}
                className="text-black text-2xl font-semibold font-Lato leading-9 outline-none tracking-tight"
                type="text"
                placeholder="₹1,299"
              />
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per night
              </div>
              <div className="justify-start items-center inline-flex">
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  Guest price before taxes ₹1,482
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Set a security deposit price
              </div>
            </div>
            <div className="w-full mt-2.5 h-[113px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex">
              <input
                onChange={(e) => setWeekendPrice(Number(e.target.value))}
                defaultValue={pricingData?.securityDeposit}
                className="text-black text-2xl font-semibold font-Lato leading-9 outline-none tracking-tight"
                type="text"
                placeholder="₹1,299"
              />
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per night
              </div>
              <div className="justify-start items-center inline-flex">
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  Guest price before taxes ₹1,482
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-6 h-6 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Additional charges
            </div>
          </div>
          <div className="mt-4">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Cleaning fee
              </div>
            </div>
            <div className="w-full mt-2.5 h-full p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex">
              <input
                onChange={(e) => setCleaningFee(Number(e.target.value))}
                defaultValue={pricingData?.CleaningFee}
                className="text-black text-2xl font-semibold font-Lato leading-9 outline-none tracking-tight"
                type="text"
                placeholder="₹0"
              />
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per stay
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Extra guest fee
              </div>
            </div>
            <div className="w-full mt-2.5 h-full p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex">
              <input
                onChange={(e) => setExtraGuestFee(Number(e.target.value))}
                defaultValue={pricingData?.ExtraGuestFee}
                className="text-black text-2xl font-semibold font-Lato leading-9 outline-none tracking-tight"
                type="text"
                placeholder="₹0"
              />
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per extra guest; per night
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Custom fee
              </div>
            </div>
            <div className="w-full mt-2.5 h-full p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex">
              <input
                onChange={(e) => setCustomFee(Number(e.target.value))}
                defaultValue={pricingData?.CustomFee}
                className="text-black text-2xl font-semibold font-Lato leading-9 outline-none tracking-tight"
                type="text"
                placeholder="₹0"
              />
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per stay
              </div>
            </div>
          </div>
          <div className="w-full mt-6 h-6 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Discounts
            </div>
          </div>
          <div className="mt-4">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Weekly discount
              </div>
            </div>
            <div className="w-full mt-2.5 h-[113px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start  text-2xl font-semibold inline-flex">
              <div>
                <input
                  onChange={(e) => setWeeklyDiscount(Number(e.target.value))}
                  defaultValue={pricingData?.WeeklyDiscount}
                  className="text-black text-2xl font-semibold font-Lato w-[36px]  leading-9 outline-none tracking-tight"
                  type="text"
                />
                %
              </div>
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per stay
              </div>
              <div className="justify-start items-center inline-flex">
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  Guest price before taxes ₹9,269
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Monthly discount
              </div>
            </div>
            <div className="w-full mt-2.5 h-[113px] p-4 bg-white rounded border font-semibold text-2xl border-gray-300 flex-col justify-start items-start inline-flex">
              <div>
                <input
                  onChange={(e) => setMonthlyDiscount(Number(e.target.value))}
                  defaultValue={pricingData?.MonthlyDiscount}
                  className="text-black text-2xl font-semibold font-Lato w-[36px]  leading-9 outline-none tracking-tight"
                  type="text"
                  placeholder="15%"
                />
                %
              </div>
              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per night
              </div>
              <div className="justify-start items-center inline-flex">
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  Guest price before taxes ₹35,256
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Custom discount
              </div>
            </div>
            <div className="w-full mt-2.5 h-[113px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start inline-flex text-2xl font-semibold">
              <div>
                <input
                  onChange={(e) => setCustomDiscount(Number(e.target.value))}
                  defaultValue={pricingData?.CustomDiscount}
                  className="text-black text-2xl font-semibold font-Lato w-[36px]  leading-9 outline-none tracking-tight"
                  type="text"
                  placeholder="15%"
                />
                %
              </div>

              <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                per stay
              </div>
              <div className="justify-start items-center inline-flex">
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  Guest price before taxes ₹1,324
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[372px] h-full ">
          <div className="w-full mb-2 h-6 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Pricing details
            </div>
          </div>
          <div className="w-full h-[516px]  py-6  rounded-lg border border-gray-300 flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full h-[521px] flex-col justify-start items-center gap-12 flex">
              <div className="flex-col justify-start items-center gap-4 flex">
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className="text-black text-4xl font-semibold font-Lato text-nowrap leading-[54px] tracking-tight">
                    {total - totalMinue}
                  </div>
                </div>
                <div className="2xl:pl-[30px] pr-[20px] pl-[20px] 2xl:pr-[30px] py-[25px] bg-white rounded-lg border border-gray-300 flex-col justify-center items-center flex">
                  <div className="self-stretch flex-col justify-start items-start gap-4 inline-flex">
                    <div className="justify-start items-start gap-[98px] inline-flex">
                      <div className="flex-col justify-start items-start gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Base price
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Weekend price
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Cleaning fee
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Extra guest fee
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Custom fee
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Weekly discount
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Monthly discount
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            Custom discount
                          </div>
                        </div>
                        {/* <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                          Guest service fee
                        </div> */}
                      </div>
                      <div className="flex-col justify-start items-end gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            {baseListingPrice}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            {WeekendPrice}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            {CleaningFee}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            {ExtraGuestFee}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            {CustomFee}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            -
                            {(JSON.parse(baseListingPrice) *
                              JSON.parse(WeeklyDiscount)) /
                              100}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            -
                            {(JSON.parse(baseListingPrice) *
                              JSON.parse(MonthlyDiscount)) /
                              100}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                            -
                            {(JSON.parse(baseListingPrice) *
                              JSON.parse(CustomDiscount)) /
                              100}
                          </div>
                        </div>
                        {/* <div className="text-zinc-950 text-base font-normal font-Lato text-nowrap leading-normal tracking-tight">
                          ₹183
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-end gap-x-3 mt-[72px]">
        <Link
          to="/ListYourVilla"
          className="w-[215px] h-12 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-zinc-950 text-base font-bold font-Lato leading-none text-nowrap">
            Go back to property details
          </div>
        </Link>
        <div
          onClick={() => handleNavigate()}
          className="w-[167px] h-12 px-6 py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
            Add more options
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
