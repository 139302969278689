import facebook from "../../../assets/svg/facebook.webp";
import instagram from "../../../assets/svg/instagram.webp";
import linkedin from "../../../assets/svg/linkedin.webp";
import banner from "../../../assets/svg/thingstodocover.webp";

const Banner = () => {
  return (
    <div className="sm:h-[602px] relative font-[butler] h-[300px]">
      <img src={banner} alt="banner" className="w-full h-full object-cover" />

      <div className="absolute bg-black/30 h-full w-full top-0">
        <h1 className="sm:mt-[174px] mt-14 md:ms-[158px] text-center md:text-start text-white sm:text-[56px] text-[30px] font-bold font-butler leading-[67.20px] tracking-wide">
        Get Started
        </h1>
        <div className="md:w-[549px] w-3/4 sm:w-full mx-auto text-white sm:text-2xl text-xs font-semibold md:ms-[158px] sm:leading-9 tracking-tight">
          We’re always here to help, feel free to reach out and we will get back
          to you.
        </div>
      </div>

      <div className="w-full flex gap-x-8 pt-8 md:ps-[158px] z-30 absolute bottom-0 h-20 sm:bg-slate-900/80 pl-5 sm:mx-0">
        <a href="https://www.facebook.com/UkiyoStays/" target="_blank" rel="noopener noreferrer">
          <img src={facebook} className="w-8 h-8" alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/ukiyostays/" target="_blank" rel="noopener noreferrer">
          <img src={instagram} className="w-8 h-8" alt="Instagram" />
        </a>
        <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A75879401&keywords=ukiyo%20stays&origin=RICH_QUERY_SUGGESTION&position=0&searchId=a6adcc2f-c729-4716-9df7-90a7407e15cb&sid=~75&spellCorrectionEnabled=false" target="_blank" rel="noopener noreferrer">
          <img src={linkedin} className="w-8 h-8" alt="LinkedIn" />
        </a>
      </div>
    </div>
  );
};

export default Banner;
