import { useState } from "react";

const FaqModal = ({ setShowFaqModal, setFaqsData }) => {
  const [faqs, setFaqs] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  const handleAddFaq = () => {
    setFaqs([
      ...faqs,
      {
        question: "",
        answer: "",
      },
    ]);
  };

  const handleSave = () => {
    setFaqsData(faqs);
    setShowFaqModal(false);
  };

  const handleChange = (index, field, value) => {
    const newFaqs = [...faqs];
    newFaqs[index][field] = value;
    setFaqs(newFaqs);
  };

  return (
    <div className="w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black/30 z-50 example overflow-scroll flex justify-center items-center">
      <div className="w-[424px] mx-auto h-[485px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
        <div className="w-full text-center text-zinc-950 text-xl font-bold font-Lato mx-auto leading-[30px]">
          Add FAQs
        </div>

        <div className="self-stretch overflow-y-auto h-[316px] flex-col justify-start items-center gap-6 flex">
          {faqs?.map((faq, index) => (
            <div
              key={index}
              className="self-stretch flex-col justify-start items-center gap-6 flex"
            >
              <div className="self-stretch justify-center items-start gap-6 inline-flex">
                <div className="grow shrink basis-0 text-zinc-950 text-lg font-bold font-Lato leading-7">
                  FAQ {index + 1}
                </div>
                <div className="bg-white rounded-lg justify-start items-start gap-2.5 flex">
                  <button
                    onClick={handleAddFaq}
                    className="text-blue-600 text-sm font-bold font-Lato uppercase leading-[21px]"
                  >
                    + Add FAQ
                  </button>
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <input
                  type="text"
                  placeholder="Question"
                  value={faq.question}
                  onChange={(e) =>
                    handleChange(index, "question", e.target.value)
                  }
                  className="self-stretch outline-none h-12 px-4 py-3 rounded border border-gray-300"
                />
                <textarea
                  placeholder="Answer"
                  value={faq.answer}
                  onChange={(e) =>
                    handleChange(index, "answer", e.target.value)
                  }
                  className="self-stretch resize-none h-24 px-4 py-3 rounded outline-none border border-gray-300"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
          <button
            onClick={handleSave}
            className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
          >
            <div className="text-white text-base font-bold font-Lato leading-none">
              Save
            </div>
          </button>

          <div
            role="button"
            onClick={() => setShowFaqModal(false)}
            className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqModal;
