import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import propertyCardImage from "../../assets/images/RecommendedImg.webp";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import "../../components/Banner/Slider/slider.css";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetRecentlyViewQuery } from "../../redux/features/home/homeApi";
import Container from "../ui/Container";
import CardTwo from "./CardTwo";

const RecentlyView = () => {
  /** Define State */
  const token = useTokenFromLocalStorage();
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  /** RTK-query HOOKS for get Recently View Properties Data */
  const { data: recentlyVP } = useGetRecentlyViewQuery(token);
  console.log(recentlyVP);
  /** SWIPER SLIDER - handler Functions */
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  /** Slider Next Handler */
  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  /** Slider Prev Handler */
  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <Container>
      <div className="lg:mt-36 mt-[60px] max-lg:mt-[56px] px-2 lg:px-7">
        {/* destination navbar start */}
        <p className="md:text-[32px] text-2xl text-[#000000] font-[Butler] text-[400] ">
          Recently Viewed Properties
        </p>
        {/* destination navbar end */}

        {/* properties cards slide start */}
        <div className="mt-[36px]">
          <div className="w-full">
            <Swiper
              modules={[Pagination, Autoplay, A11y]}
              ref={swiperRef}
              pagination={{ clickable: true }}
              loop={false}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSlideChange();
              }}
              breakpoints={{
                1440: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 2,
                },
                368: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={30}
              onSlideChange={handleSlideChange}
              className="max-lg:max-w-screen-md w-full"
            >
              {recentlyVP?.data?.map((property) => (
                <SwiperSlide key={property?.id}>
                  <div className="flex justify-center">
                    {/* Send Data to Card by Props */}
                    <CardTwo
                      key={property?.id}
                      propertyData={property}
                      tempImage={propertyCardImage}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* indication for slide Start*/}
          <div className="flex items-center justify-end mt-[32px] gap-[16px]">
            <button
              onClick={swipePrev}
              className={`${
                isBeginning ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <img
                src={isBeginning ? scrollLeft : scrollLeftActive}
                alt="scroll"
              />
            </button>
            <button
              onClick={swipeNext}
              className={`${isEnd ? "cursor-not-allowed" : "cursor-pointer"}`}
            >
              <img src={scrollRight} alt="scroll" />
            </button>
          </div>
          {/* indication for slide End */}
        </div>
        {/* properties cards slide end */}
      </div>
    </Container>
  );
};

export default RecentlyView;
