import { useState } from "react";
import { FaChevronRight, FaLink } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOnsModal from "./AddOnsModal";
import FaqModal from "./FaqModal";
import MealPlans from "./MealPlans";
import PropertyDetailsModal from "./PropertyDetailsModal"; // Ensure this import

const AddMoreOptions = () => {
  const location = useLocation();
  console.log(location);
  const bookingTypeData = ["EntireVilla", "1 room", "2 room", "3 room"];
  const [bookingType, setBookingType] = useState(bookingTypeData[0]);
  const [showBookingType, setShowBookingType] = useState(false);
  const [addOnsData, setAddOnsData] = useState({});
  const [showAddOnsModal, setShowAddOnsModal] = useState(false);
  const [propertyDetailsModal, setPropertyDetailsModal] = useState(false);
  const [showMealsPlans, setShowMealsPlans] = useState(false);
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [PropertyDetails, SetPropertyDetails] = useState([]);
  const [mealPlansData, setMealPlansData] = useState([]);
  const [houseRules, setHouseRules] = useState("");
  const [Faqs, setFaqs] = useState([]);
  const navigate = useNavigate();
  const [CheckIn, setCheckIn] = useState("");
  const [CheckOut, setCheckOut] = useState("");
  const [brochureLink, setBrochureLink] = useState("");
  const PricingDetails = JSON.parse(localStorage?.getItem("pricingDetails"));
  console.log("🚀 ~ AddMoreOptions ~ PricingDetails:", PricingDetails);
  const PropertyDetailsData = JSON.parse(
    localStorage?.getItem("PropertyDetails")
  );

  console.log(PricingDetails, PropertyDetailsData);
  const HandleSubmit = async () => {
    const Data = {
      name: PropertyDetailsData?.HotelName,
      companyName: "Luxury Hotels Inc.",
      supplierName: PropertyDetailsData?.SupplierName,
      address: PropertyDetailsData?.ListingAddress,
      city: PropertyDetailsData?.City,
      subCity: PropertyDetailsData?.State,
      locationPin: PropertyDetailsData?.PinCode,
      managerId: "663b75d84974c965975b3c8f",
      country: PropertyDetails?.CountryCode?.name,
      contactNumber: JSON.stringify(PropertyDetailsData?.Phone),
      rating: 4.56,
      isLuxe: true,
      isFeatured: true,
      isExecutive: false,
      Description: JSON.stringify(PropertyDetailsData?.PropertyDescription),
      TypeOfListing: JSON.stringify(PropertyDetailsData?.TypeOfListing),
      hotelType: "Luxury",
      hotelOverview: "A luxurious stay with all modern amenities.",
      USPs: ["Prime location", "Great service", "Exquisite food"],
      amenities: PropertyDetailsData?.Amenities,
      reviews: ["60f4d6d74f1a4e3c1c8a2b35"],
      tags: ["luxury", "city-center"],
      imageUrls: PropertyDetailsData?.PropertyDetailsImg,
      pricePerNight: 5000,
      checkInTime: CheckIn,
      checkOutTime: CheckOut,
      noOfRooms: PropertyDetailsData?.NumberOfBedrooms,
      noOfBedrooms: PropertyDetailsData?.NumberOfBeds,
      noOfBathrooms: 5,
      maxGuests: PropertyDetailsData?.NumberOfGuests,
      websiteLink: "https://luxurystay.com",
      mealCost: 1000,
      securityDeposit: 2000,
      status: "Pending",
      isArchived: false,
      brochureLink: brochureLink,
      pricingDetails: PricingDetails,
      TypeOfBooking: bookingType,
      addOns: [addOnsData],
      roomDetails: PropertyDetailsData,
      mealPlan: mealPlansData,
      FAQs: Faqs,
      LandMark: PropertyDetails?.Landmark,
      houseRules: houseRules,
    };
    console.log(Data);
    const response = await fetch(
      `https://api.ukiyostays.com/api/hotels/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage?.getItem("token")}`,
        },
        body: JSON.stringify(Data),
      }
    );

    const responseData = await response.json();
    if (responseData?.success === true) {
      localStorage.setItem("AddMoreOption", "true");
      navigate("/ListYourVilla/PropertyAddedSuccess");
    }

    console.log(responseData);
  };

  return (
    <div>
      <div className="w-[65vw] mx-auto">
        <div className="w-[257px] mb-12 h-12 flex-col justify-start items-start gap-10 inline-flex">
          <div className="justify-start items-center gap-[670px] inline-flex">
            <div className="h-12 justify-start items-start gap-[702px] flex">
              <div className="justify-center items-center gap-4 flex">
                <div className="text-zinc-950 text-[32px] font-normal font-butler leading-[48px] tracking-tight">
                  Add more details
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          id="gridDevide"
          className="grid grid-cols-2 gap-x-6 w-full h-full"
        >
          <div className="w-full h-full">
            <div className="relative">
              <div className="w-[416px] h-6 flex-col justify-start relative items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Booking Type
                </div>
              </div>
              <div
                onClick={() => setShowBookingType(!showBookingType)}
                className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex"
              >
                <div className="h-full w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight">
                  {bookingType}
                </div>
                <FaChevronRight className="absolute right-4 rotate-90 top-4" />
              </div>
              <div className="absolute z-30 top-[90px] right-0 bg-white w-full">
                {showBookingType &&
                  bookingTypeData?.map((item) => (
                    <div
                      key={item}
                      onClick={() => {
                        setBookingType(item);
                        setShowBookingType(false);
                      }}
                      className="w-full mt-2.5 py-3 p-4 bg-white rounded hover:bg-gray-200 cursor-pointer flex-col justify-start items-start relative inline-flex"
                    >
                      <div className="h-full w-full outline-none text-black">
                        {item}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-full h-20 mt-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Check-In Time
              </div>

              <input
                type="text"
                name="CheckIn"
                required
                onChange={(e) => setCheckIn(e.target.value)}
                defaultValue={PropertyDetails?.CheckIn}
                className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Check-In Time"
              />
            </div>
            <div className="mt-8">
              <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Property details
                </div>
              </div>
              <div className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex">
                <div
                  role="button"
                  onClick={() => setPropertyDetailsModal(true)}
                  className="h-full w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight"
                >
                  Add property details
                </div>
                <FaChevronRight className="absolute right-4 top-4" />
              </div>
            </div>
            <div className="mt-8">
              <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Meal plans
                </div>
              </div>
              <div
                role="button"
                onClick={() => setShowMealsPlans(true)}
                className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex"
              >
                <div
                  role="button"
                  className="h-full w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight"
                >
                  Add Meal plans
                </div>
                <FaChevronRight className="absolute top-5 right-4" />
              </div>
            </div>
            <div className="mt-8">
              <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add FAQs
                </div>
              </div>
              <div
                role="button"
                onClick={() => setShowFaqModal(true)}
                className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex"
              >
                <div className="h-full w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight">
                  Add FAQs
                </div>
                <FaChevronRight className="absolute right-4 top-4" />
              </div>
            </div>
            <div className="mt-8">
              <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Brochure link
                </div>
              </div>
              <div className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex">
                <input
                  onChange={(e) => setBrochureLink(e.target.value)}
                  placeholder="Add brochure link"
                  className="h-full w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight"
                  type="text"
                />

                <FaLink className="absolute right-4 text-xl top-3" />
              </div>
            </div>
          </div>
          <div className="w-full h-full">
            <div className="">
              <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add On’s
                </div>
              </div>
              <div className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex">
                <div
                  role="button"
                  onClick={() => setShowAddOnsModal(!showAddOnsModal)}
                  className="h-full w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight"
                >
                  Add On’s
                </div>
                <FaChevronRight className="absolute right-4" />
              </div>
            </div>
            <div className="w-full h-20 mt-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Check-Out Time
              </div>

              <input
                type="text"
                name="CheckOut"
                required
                onChange={(e) => setCheckOut(e.target.value)}
                defaultValue={PropertyDetails?.CheckOut}
                className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Check-In Time"
              />
            </div>
            <div className="mt-8">
              <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  House rules
                </div>
              </div>
              <div className="w-full mt-2.5 py-3 p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start relative inline-flex">
                <textarea
                  type="text"
                  onChange={(e) => setHouseRules(e.target.value)}
                  placeholder="Write some house rules"
                  className="h-[159px] resize-none w-full outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight"
                />
              </div>
            </div>
          </div>
        </section>

        <div className="flex justify-end gap-x-3 mt-[168px]">
          <Link
            to="/ListYourVilla/PriceDetails"
            className="w-[215px] h-12 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-zinc-950 text-base font-bold font-Lato leading-none text-nowrap">
              Go back to pricing details
            </div>
          </Link>
          <div
            role="button"
            onClick={() => HandleSubmit()}
            className="w-[167px] h-12 px-6 py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
              Submit
            </div>
          </div>
        </div>
      </div>
      {showAddOnsModal && (
        <AddOnsModal
          setShowAddOnsModal={setShowAddOnsModal}
          setAddOnsData={setAddOnsData}
        />
      )}
      {propertyDetailsModal && (
        <PropertyDetailsModal
          SetPropertyDetails={SetPropertyDetails}
          setPropertyDetailsModal={setPropertyDetailsModal}
        />
      )}
      {showMealsPlans && (
        <MealPlans
          setMealPlansData={setMealPlansData}
          setShowMealsPlans={setShowMealsPlans}
        />
      )}
      {showFaqModal && (
        <FaqModal setShowFaqModal={setShowFaqModal} setFaqsData={setFaqs} />
      )}
    </div>
  );
};

export default AddMoreOptions;
