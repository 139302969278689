import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Amenities from "../../components/PropertyDetails/Amenities";
import CancellationPolicy from "../../components/PropertyDetails/CancellationPolicy";
import ContactButton from "../../components/PropertyDetails/ContactButton";
import CuponCard from "../../components/PropertyDetails/CuponCard";
import Experiences from "../../components/PropertyDetails/Experiences";
import Faqs from "../../components/PropertyDetails/FAQs/Faqs";
import Map from "../../components/PropertyDetails/FAQs/Map";
import FoundersReview from "../../components/PropertyDetails/FoundersReview";
import Gallery from "../../components/PropertyDetails/HeroSection/Gallery";
import Heading from "../../components/PropertyDetails/HeroSection/Heading";
import HouseRules from "../../components/PropertyDetails/HouseRules";
import MealPlans from "../../components/PropertyDetails/MealPlans";
import HotelBooking from "../../components/PropertyDetails/PropertyBooking/HotelBooking";
import { PriceTable } from "../../components/PropertyDetails/PropertyBooking/PriceTable";
import PropertyDetailsStepner from "../../components/PropertyDetails/PropertyDetailsStepner";
import RealMoments from "../../components/PropertyDetails/RealMoments";
import Review from "../../components/PropertyDetails/Review";
import Reviews from "../../components/PropertyDetails/Reviews";
import RoomDetails from "../../components/PropertyDetails/RoomDetails";
import SimilarProperties from "../../components/PropertyDetails/SimilarProperties/SimilarProperties";
import VillaDetails from "../../components/PropertyDetails/VillaDetails";
import Container from "../../components/ui/Container";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetHotelsByCitiesQuery } from "../../redux/features/futureGetaways/futureGetawaysApi";
import { useGetPropertyQuery } from "../../redux/features/home/homeApi";

const PropertyDetails = () => {
  const { id } = useParams();
  const token = useTokenFromLocalStorage();
  const { data: particularPropery } = useGetPropertyQuery({ token, id });
  const hotelData = particularPropery?.data?.hotel;
  const { data: similarHotelData } = useGetHotelsByCitiesQuery(hotelData?.city);
  const price = hotelData?.pricePerNight;
  const hotelId = hotelData?._id;

  useEffect(() => {
    window.scrollTo(0, 0);
    // const scrollToBottom = () => {
    //   window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    // };

    // if (hotelData) {
    //   scrollToBottom();
    // }
  }, [hotelData]);
  const [tell, setTell] = useState();
  function tellme(val) {
    setTell(val);
  }
  return (
    <>
      <Container>
        <Heading
          _id={hotelData?._id}
          hotelName={hotelData?.name}
          hotelAddress={hotelData?.address}
          hotelCity={hotelData?.city}
        />
        <Gallery hotelImages={hotelData?.imageUrls} _id={hotelData?._id} />

        {/* layout */}
        <div className="flex items-start justify-between gap-[24px] max-lg:mt-6 max-xl:flex-col mt-[48px]">
          {/* left section */}
          <div className="max-xl:w-full w-[856px] ">
            {/* review */}
            <Review
              hotelRating={hotelData?.rating}
              hotelReviews={hotelData?.reviews}
            />
            {/* Villa Details */}
            <VillaDetails />
            {/* {PropertyDetails Tab} */}
            <div className="mt-[80px] max-lg:mt-0 ">
              <PropertyDetailsStepner
                propertyOverview={hotelData?.hotelOverview}
              />
            </div>
            {/* Founders Review */}
            <FoundersReview Id={hotelId} />
            {/* Room Details */}
            <RoomDetails hotelRooms={hotelData?.rooms} />

            {/* Amenities */}
            <Amenities amenitiesData={hotelData?.amenities} />
            {/* Meal Plans */}
            <MealPlans
              hotelName={hotelData?.name}
              hotelAddress={hotelData?.address}
              hotelCity={hotelData?.city}
              hotelMealPlans={hotelData?.mealPlans}
            />
            {/* Reviews */}
            <Reviews hotelsReviews={hotelData?.reviews} />
            {/* Real Moments */}
            <RealMoments />
            {/* Experiences */}
            <Experiences />
            {/* House Rules */}
            <HouseRules houseRules={hotelData?.houseRules} />
            {/* Cancellation Policy */}
            <CancellationPolicy
              cancallationPoliciy={hotelData?.cancellationPolicy}
            />
            {/* {Faqs page} */}
            <div className="max-md:my-0 my-[120px]">
              <Faqs faqData={hotelData?.FAQs} />
            </div>
          </div>
          {/* right section */}
          <div className="max-xl:w-full w-[416px]">
            {/* Google Map for smaller device*/}
            <div className="hidden max-xl:block">
              <Map />
            </div>
            <div></div>
            {/* Booking From */}
            <HotelBooking
              price={price}
              hotelId={hotelId}
              token={token}
              tellme={tellme}
            />
            {/* discount card */}
            <div className="max-xl:w-full px-3 mx-auto w-[380px] max-xl:hidden">
              <CuponCard />
            </div>
            {/* Contact Button */}
            <div className="max-xl:hidden">
              <ContactButton tell={tell} />
            </div>
            {/* Google Map */}
            <div className="xl:block hidden">
              <Map />
            </div>
            <div className="mt-10">
              <PriceTable />
            </div>
          </div>
        </div>

        {/* similar properties */}
        <SimilarProperties similarHotelData={similarHotelData} />
      </Container>
    </>
  );
};

export default PropertyDetails;
