import React from "react";
import abt from "../../assets/images/EventsAndExp/abt.webp";

const AboutEvents = () => {
  return (
    <div className="mt-[190px] max-w-[1298px] w-[90%] mx-auto">
    <h2 className="font-butler font-[500] leading-[54px] tracking-[0.36px] mb-10 text-[24px]  text-4xl lg:text-4xl">
  Events and Experiences
</h2>
    <div className="bg-[#F8F9FA] flex flex-col sm:flex-row lg:gap-[130px] sm:gap-4">
      <img
        src={abt}
        alt="Event"
        className="w-full sm:w-[339px] lg:w-[527px] h-auto sm:h-[245px] lg:h-[381px] object-cover"
      />
      <div className="flex justify-center items-center rounded-lg lg:bg-[#F8F9FA] p-4">
        <p className="text-[#08090A] text-[20px] font-butler lg:text-2xl font-medium sm:text-[20px] leading-7 sm:leading-8 lg:leading-9 tracking-[0.24px] lg:max-w-[527px]">
          With a passion for perfection and a commitment to excellence, we
          offer expert event planning services tailored to your unique needs.
          Our team of seasoned professionals and trusted partners are here to
          make your dreams a reality. Let us take the stress out of planning
          so you can focus on enjoying every moment.
        </p>
      </div>
    </div>
  </div>
  

  );
};

export default AboutEvents;
