import { Link } from "react-router-dom";
import ukiyoWhiteLogo from "../../assets/images/ukiyo-logo-white.webp";
import facebook from "../../assets/svg/facebook.webp";
import instagram from "../../assets/svg/instagram.webp";
import linkedin from "../../assets/svg/linkedin.webp";
import phoneCall from "../../assets/svg/phone-call.webp";
import twitter from "../../assets/svg/twitter.webp";

export default function HomeFooter() {
  return (
    <div className="bg-card text-card-foreground p-8 h-fit bg-black text-white">
      <div className="max-w-[77rem] mx-auto grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-5">
        <div className="flex flex-col space-y-4">
          <a href="#">
            <img
              src={ukiyoWhiteLogo}
              alt="Ukiyo Logo"
              className="h-[74px] w-[89px]"
            />
          </a>
          <p className="text-muted-foreground text-[16px] font-[500]">
            Exciting News! 🥳 We're crafting an immersive villa rental website
            JUST FOR YOU! Stay tuned for the grand reveal! Need help now? Our
            villa specialists are ready to find your dream escape. Ciao for now,
            wanderer!
          </p>
        </div>

        <div className="flex flex-col space-y-2 text-[15px] items-start text-start">
          <h3 className="text-lg font-semibold">Destinations</h3>
          <h3 className="text-lg font-semibold">Services</h3>
          <Link to="/AboutUs">
            <h3 className="text-lg font-semibold">About Us</h3>
          </Link>
          <h3 className="text-lg font-semibold">Why Us</h3>
        </div>

        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-semibold text-[20px]">Get in Touch</h3>
          <p className="text-muted-foreground text-[16px] font-[400]">
            13th Floor, Office no 1306, Signature Business Park, Postal Colony
            Road, Chembur East, Mumbai 400071
          </p>
          <a
            className="text[16px] text-[#fff] mt-[16px]"
            href="mailto:info@ukiyostays.com"
            target="_blank"
            rel="noreferrer"
          >
            info@ukiyostays.com
          </a>
          <div className="flex items-center justify-between">
            <a
              href="tel:+918976767650"
              className="flex items-center cursor-pointer"
            >
              <img src={phoneCall} alt="Phone Icon" className="mr-2" />
              +91 8976767650
            </a>
            <button className="bg-primary text-primary-foreground py-2 px-4 rounded-md border-gray-100 border-2">
              ENQUIRE NOW
            </button>
          </div>
          <div className="flex space-x-4 mt-4">
            <img
              src={facebook}
              alt="Facebook Icon"
              className="cursor-pointer"
            />
            <img
              src={instagram}
              alt="Instagram Icon"
              className="cursor-pointer"
            />
            <img
              src={linkedin}
              alt="LinkedIn Icon"
              className="cursor-pointer"
            />
            <img src={twitter} alt="Twitter Icon" className="cursor-pointer" />
          </div>
        </div>
      </div>
      <div className="border-t border-muted mt-8 pt-4 text-center text-muted-foreground flex flex-col md:flex-row md:justify-between">
        <div className="flex gap-5 justify-center md:justify-start px-20">
          <Link
            to="/PrivacyPolicy"
            className="text-[14px] text-nowrap text-[#fff] font-[500] cursor-pointer"
          >
            Terms & Conditions
          </Link>
          <Link
            to="/PrivacyPolicy"
            className="text-[14px] text-nowrap text-[#fff] font-[500] cursor-pointer"
          >
            Privacy Policy
          </Link>
          <Link
            to="/cancellation"
            className="text-[14px] text-nowrap text-[#fff] font-[500] cursor-pointer"
          >
            Cancellation Policy
          </Link>
        </div>
        <p className="mt-4 md:mt-0 px-20">
          &copy; 2021 Ukiyo Stays. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
