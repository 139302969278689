import React, { useEffect, useRef, useState } from "react";
import ReviewHome from "../../assets/images/review/homeImg.webp";
import star_line from "../../assets/images/review/startLine.webp";
import uploadPic from "../../assets/images/review/upload.webp";
import map from "../../assets/svg/locationMap.webp";
import starIcon from "../../assets/svg/start-icon.webp";
import { BaseURL } from "../../redux/config";

const ReviewModalContent = ({ token, setIsReview, hotelId }) => {
  const fileInputRef = useRef(null);
  const [staffRatings, setStaffRatings] = useState(Array(4).fill(0));
  const [propertyRatings, setPropertyRatings] = useState(Array(4).fill(0));
  const [locationRatings, setLocationRatings] = useState(Array(4).fill(0));
  const [facilitiesRatings, setFacilitiesRatings] = useState(Array(4).fill(0));
  const [reviewText, setReviewText] = useState("");
  const [isReviewTextEmpty, setIsReviewTextEmpty] = useState(false);
  const [reviewDataPic, setReviewDataPic] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hoveredOnImgBox, SetHoveredOnImgBox] = useState(false);

  const handleStaffRatingClick = (selectedRating, index, setRatings) => {
    setRatings((prev) => {
      const updatedRatings = [...prev];
      updatedRatings[index] = selectedRating;
      return updatedRatings;
    });
  };

  const generateStars = (ratings, index, setRatings) => {
    const stars = [];
    const maxRating = 5;
    for (let i = 1; i <= maxRating; i++) {
      const filled = i <= ratings[index];
      stars.push(
        <img
          key={i}
          src={filled ? starIcon : star_line}
          alt={filled ? "filled star" : "empty star"}
          className={`flex flex-row w-7 h-7 mt-1 m-1 cursor-pointer ${
            filled ? "text-yellow-500" : ""
          }`}
          onClick={() => handleStaffRatingClick(i, index, setRatings)}
        />
      );
    }
    return stars;
  };

  const handleSubmit = () => {
    if (reviewText.trim() === "") {
      setIsReviewTextEmpty(true);
      return;
    }
    submitReview();
  };

  const submitReview = async () => {
    console.log("numbersd:: ", staffRatings[0], " loca: ", locationRatings[0]);
    console.log("review test:: ", reviewText);
    console.log("Imagess are: ", reviewDataPic);
    const formData = new FormData();
    formData.append("text", reviewText);
    formData.append("rating", propertyRatings[0]);
    formData.append("facilityRating", facilitiesRatings[0]);
    formData.append("staffRating", staffRatings[0]);
    formData.append("locationRating", locationRatings[0]);
    for (let i = 0; i < reviewDataPic.length; i++) {
      formData.append(`images`, reviewDataPic[i]);
    }

    const data = await fetch(`${BaseURL}/reviews/create?hotelId=${hotelId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseJson = await data.json();
    console.log("Responsee iss:  ", responseJson);
    setIsReview(false);
    window.alert("Review is submitted!");
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImageUrls = [...reviewDataPic];
      for (let i = 0; i < files.length; i++) {
        newImageUrls.push(files[i]);
      }
      setReviewDataPic(newImageUrls);
    }
  };

  const validateForm = () => {
    const isFormValid =
      reviewText.trim() !== "" &&
      staffRatings[0] > 0 &&
      propertyRatings[0] > 0 &&
      facilitiesRatings[0] > 0 &&
      locationRatings[0] > 0;
    setIsSubmitDisabled(!isFormValid);
  };

  useEffect(() => {
    validateForm();
  }, [
    reviewText,
    staffRatings,
    propertyRatings,
    facilitiesRatings,
    locationRatings,
  ]);

  return (
    <div className="w-[636px] py-2 px-6 font-Lato">
      <div className="flex gap-3 mb-3">
        <div>
          <img src={ReviewHome} alt="Rectangle" className="w-[60px] h-[60px]" />
        </div>

        <div>
          <h6 className="text-[#08090A] font-[Butler] font-medium text-2xl font-[400] pl-2 font-butler">
            Hotel Indigo Goa Downtown
          </h6>
          <div className="flex mb-1 mt-1 font-medium text-[#434141] gap-1 font-[500]">
            <img src={map} alt="map" className="w-6 h-6" />
            <p>P.O. Goa University Bambolim, Goa,</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-between mt-3 mr-6">
        <div>
          <h1 className="text-[#08090A] font-[700] text-[16px] ">
            How would you rate our staff?
          </h1>
          <div className="flex flex-row">
            {generateStars(staffRatings, 0, setStaffRatings)}
          </div>
        </div>
        <div>
          <h1 className="text-[#08090A] font-[700] text-[16px] ">
            How would you rate our property?
          </h1>
          <div className="flex flex-row">
            {generateStars(propertyRatings, 0, setPropertyRatings)}
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-3 mr-6">
        <div>
          <h1 className="text-[#08090A] font-[700] text-[16px] ">
            How would you rate our facilities?
          </h1>
          <div className="flex flex-row">
            {generateStars(facilitiesRatings, 0, setFacilitiesRatings)}
          </div>
        </div>
        <div>
          <h1 className="text-[#08090A] font-[600] text-lg">
            How would you rate our location?
          </h1>
          <div className="flex flex-row">
            {generateStars(locationRatings, 0, setLocationRatings)}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <p className="text-[#08090A] font-[600] text-[16px] ">
          Write your review
        </p>
        <div>
          <textarea
            onChange={(e) => {
              setReviewText(e.target.value);
              setIsReviewTextEmpty(false);
            }}
            className="border border-[#CED4DA] w-[600px] h-[145px] outline-none rounded-md placeholder-[#6D747A] resize-none px-2 pt-2 placeholder:w-96"
            placeholder="What did you like or dislike about the villa or your stay?"
          />
        </div>
        {isReviewTextEmpty && (
          <p className="text-red-500 mt-2">Please enter your review.</p>
        )}
      </div>

      <div className="mt-6 flex flex-row gap-3 relative">
        <div className="flex justify-start mb-3 max-w-[100px] max-h-[100px]">
          <input
            type="file"
            name="file"
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            className="h-full w-full"
            ref={fileInputRef}
          />
          <label
            htmlFor="fileInput"
            className="relative"
            onMouseOver={() => SetHoveredOnImgBox(true)}
            onMouseLeave={() => SetHoveredOnImgBox(false)}
          >
            <img
              src={uploadPic}
              alt="Upload Pic"
              className="w-[100px] h-[100px] rounded-lg cursor-pointer border border-black"
              onClick={() => fileInputRef.current.click()}
            />
            {hoveredOnImgBox && (
              <div className="absolute top-full mt-2 left-0 px-4 py-2 bg-[#08090A] rounded-sm shadow text-white text-base font-medium">
                Add photos
              </div>
            )}
          </label>
        </div>
        <div className="flex flex-wrap gap-3">
          {reviewDataPic?.map((image, i) => {
            const imageUrl = URL.createObjectURL(image);
            return (
              <div
                key={i}
                className="max-w-[100px] max-h-[100px] overflow-hidden rounded-lg"
              >
                <img
                  src={imageUrl}
                  alt="selected"
                  className="w-full h-full object-cover"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-10 mb-4 flex justify-center items-center">
        <button
          className="w-[220px] h-[48px] text-lg rounded-md text-white bg-black"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ReviewModalContent;
