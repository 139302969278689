import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import heartIconChange from "../../assets/svg/heart-red.webp";
import heartIcon from "../../assets/svg/heart.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAddWishlistMutation } from "../../redux/features/userDashboard/userDashboardApi";
import { debounce } from "../../Utils/Debouce";

const CardTwo = ({ propertyData }) => {
  console.log(propertyData);
  /** Get Token Using Custom Hooks */
  const token = useTokenFromLocalStorage();

  /** RTK-query Mutation Hooks for add Wishlist data */
  const [addWishlist, { isSuccess, isError, error }] = useAddWishlistMutation();

  /** Local state to manage wishlist status */
  const [isWishlisted, setIsWishlisted] = useState(false);

  if (isError) {
    alert(error?.data?.message);
  }

  /** Handler Function for Add Wishlist */
  const handleAddWishlist = (propertyId) => {
    addWishlist({ token, propertyId });
    setIsWishlisted(!isWishlisted);
  };

  /** Debounced Version of the Wishlist Handler -- Debounce come from Utils */
  const debouncedAddWishlist = debounce(handleAddWishlist, 500);

  return (
    <>
      <div className="rounded-sm shadow-md shadow-slate-200 w-[342px] lg:w-[300px]">
        <div className="relative">
          <Link
            to={`/propertyDetails/${
              propertyData?._id !== undefined && propertyData?._id
            }`}
          >
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              autoplay
              onSwiper={() => {}}
              slidesPerView={1}
              className="h-[256px]"
            >
              {propertyData?.imageUrls !== undefined &&
                propertyData?.imageUrls?.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="w-full object-cover h-full"
                      src={
                        img ||
                        "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png"
                      }
                      alt="property-image"
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </Link>
          <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[12px] px-[8px] py-[4px] rounded-[4px] font-[600] z-30">
            {propertyData?.tags !== undefined && propertyData?.tags?.[0]}
          </button>
          <button
            onClick={() =>
              debouncedAddWishlist(
                propertyData?._id !== undefined && propertyData?._id
              )
            }
          >
            <img
              className="absolute top-[16px] right-[16px] cursor-pointer z-30"
              src={isWishlisted ? heartIconChange : heartIcon}
              alt="icon"
            />
          </button>
        </div>

        {/* Card info */}
        <Link
          to={`/propertyDetails/${
            propertyData?._id !== undefined && propertyData?._id
          }`}
        >
          <div className="px-[16px] py-[12px]">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[18px] text-[#000]">
                {propertyData?.name !== undefined && propertyData?.name}
              </p>
              <span className="text-[#6D747A] text-[14px]">
                {propertyData?.address !== undefined &&
                  propertyData?.address?.slice(0, 26)}
                , {propertyData?.city !== undefined && propertyData?.city}
              </span>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default CardTwo;
