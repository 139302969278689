import React, { useEffect, useState} from "react";
// import locationMap from "../../assets/images/map-pin.png";
import { BsSuitcaseLg } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import { MdContactPhone, MdOutlineVilla } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import building2 from "../../assets/images/builIcon.webp";
import bottomIcon from "../../assets/images/Faqs/bottom.webp";
import topIcon from "../../assets/images/Faqs/topIcon.webp";
import locationMap from "../../assets/svg/search-map-pin.webp";
import { BaseURL } from "../../redux/config";
import DropdownWithScroll from "./DropdownConten";
import "../Navbar/drop.css";
import "./navbar.css";


const Dropdown = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [villaData, setVillas] = useState()
  const [luxuryvillaData, setLuxuryVillas] = useState()
  const [International, setInternational] = useState()

  
 
 

  const [isHovered1, setIsHovered1] = useState(false);
  const [isLocationEmpty, setIsLocationEmpty] = useState(false);

  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  const villasData = async (hotelType) => {
    const url = `https://api.ukiyostays.com/api/hotels/refers?hotelType=${hotelType}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      return data;
      
    } catch (error) {
      console.error('Error fetching villas data:', error);
      return null; 
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const villa = await villasData("Villa");
      const luxury = await villasData("Luxury Villas");
      const international = await villasData("International");

      if (villa) setVillas(villa); 
      if (luxury) setLuxuryVillas(luxury); 
      if (international) setInternational(international); 
    };

    fetchData(); 
  }, []);

  
  const navigateTo = (location) => {
    navigate("/allproperties", {
      state: {
        location: location,
      },
    });
  };
  const handleSearch = async (location) => {
    setIsHovered1(false);
    setIsHovered2(false);
    setIsHovered3(false);
    // let locationName = location.slice(10, location.length);
    // console.log(locationName,"location");
    try {
      // // Validate inputs
      // if (locationName.trim() === "") {
      //   setIsLocationEmpty("Please Select a Location");
      //   return;
      // }
      // // console.log("api")
      // // Perform API call
      const response = await fetch(
        `${BaseURL}/hotels/search?city=${location}i&hotelType=Villas`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response,"RES")
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log("Response data for Villas:", responseData);

      // Navigate to results page with search data
      navigateTo(location);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleSearch1 = async (location) => {
    // let locationName = location.slice(10, location.length);
    // console.log(locationName);
    try {
      // Validate inputs
      // if (locationName.trim() === "") {
      //   setIsLocationEmpty("Please Select a Location");
      //   return;
      // }

      // Perform API call
      const response = await fetch(
        `${BaseURL}/hotels/search?city=${location}/&tags=luxury`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Response data for Elites:", responseData);

      // Navigate to results page with search data
      navigateTo(location);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleSearch3 = async (location) => {
    // console.log(location);
    // let locationName = location.slice(10, location.length);
    try {
      // Validate inputs
      // if (locationName.trim() === "") {
      //   setIsLocationEmpty("Please Select a Location");
      //   return;
      // }

      // Perform API call
      const response = await fetch(
        `${BaseURL}/hotels/search?city=${location}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Response data for international:", responseData);

      // Navigate to results page with search data
      navigateTo(location);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  }, []);
  return (
    <>
      {/* Dropdown menu 1 */}
      <div className="relative inline-block w-full h-full gap-[84px]">
        <button
          id="dropdownHoverButton"
          data-dropdown-toggle="dropdownHover"
          data-dropdown-trigger="hover"
          className="text-[#08090A] w-full lg:w-fit font-medium lg:text-[18px] xl:text-xl lg:pl-2 py-2.5 md:pl-4 text-center inline-flex lg:ms-0  justify-between lg:justify-normal items-center relative"
          type="button"
          onMouseEnter={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}
          onClick={() => {
            setIsHovered1(!isHovered1);
            setIsHovered2(false);
            setIsHovered3(false);
          }}
        >
          <div className="flex gap-x-2 items-center text-[16px] font-[500px]">
            <MdOutlineVilla className="block w-5 h-5 lg:hidden" /> Villas
          </div>
          {isHovered1 ? (
            <img src={bottomIcon} alt="bottomIcon" className="w-4 h-4 ml-2" />
          ) : (
            <img src={topIcon} alt="topIcon" className="w-4 h-4 ml-2" />
          )}
        </button>

        <div
          id="dropdownHover"
          onMouseEnter={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}
          className={`w-[989px] h-[411px] p-4  overflow-hidden shadow-2xl data z-10 lg:absolute top-full left-0  rounded-lg lg:shadow ${isHovered1 ? "block" : "hidden"
            }`}
        >
          {villaData?.data && 
        <DropdownWithScroll villaData={villaData} handleSearch1={handleSearch} />
        }
        </div>
      </div>

      {/* Dropdown menu 2 */}
      <div className="relative inline-block w-full">
        <button
          id="dropdownHoverButton"
          data-dropdown-toggle="dropdownHover"
          data-dropdown-trigger="hover"
          className="text-[#08090A]   font-medium w-full  lg:text-[18px] xl:text-xl  justify-between lg:justify-normal lg:pl-2 md:pl-0 py-2.5 text-center inline-flex items-center  text-nowrap relative"
          type="button"
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          onClick={() => {
            //handleMouseEnter2();
            setIsHovered2(!isHovered2);
            setIsHovered1(false);
            setIsHovered3(false);
          }}
        >
          <div className="flex gap-x-2 items-center justify-items-start text-[16px] font-[500px] ">
            <BsSuitcaseLg className="block lg:hidden" /> Elite Villas
          </div>
          {isHovered2 ? (
            <img src={bottomIcon} alt="bottomIcon" className="w-4 h-4 ml-2" />
          ) : (
            <img src={topIcon} alt="topIcon" className="w-4 h-4 ml-2" />
          )}
        </button>

        <div
          id="dropdownHover"
          className={`w-[989px] h-[411px]  px-4 shadow-2xl data z-10 lg:absolute top-full left-0 rounded-lg lg:shadow   ${isHovered2 ? "block" : "hidden"
            }`}
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
        >
          <DropdownWithScroll villaData={luxuryvillaData} handleSearch1={handleSearch1} />
          
        </div>
      </div>
      {/* Dropdown menu 3 */}

      <div className="relative inline-block w-full">
        <button
          id="dropdownHoverButton"
          data-dropdown-toggle="dropdownHover"
          data-dropdown-trigger="hover"
          className="text-[#08090A]   font-medium   lg:text-[18px] xl:text-xl lg:px-1 justify-between w-full lg:justify-normal py-2.5 text-center inline-flex items-center  text-nowrap relative"
          type="button"
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
          onClick={() => {
            setIsHovered3(!isHovered3);
            setIsHovered1(false);
            setIsHovered2(false);
          }}
        >
          <div className="flex gap-x-2 items-center text-[16px] font-[500px]">
            <FaRegHeart className="block lg:hidden" /> International properties
          </div>
          {isHovered3 ? (
            <img src={bottomIcon} alt="bottomIcon" className="w-4 h-4 ml-2" />
          ) : (
            <img src={topIcon} alt="topIcon" className="w-4 h-4 ml-2" />
          )}
        </button>

        <div
          id="dropdownHover"
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
          className={`w-[668px] h-auto p-2 shadow-2xl data z-10 lg:absolute top-full left-0  rounded-lg lg:shadow  ${isHovered3 ? "block" : "hidden"
            }`}
        >
          <DropdownWithScroll villaData={International} handleSearch1={handleSearch3} />
          
        </div>
      </div>

      <div className="relative flex items-center z-20 pe-2 py-2.5 me-2">
        <Link to="/ContactUs" className="flex items-center gap-2">
          <MdContactPhone className="text-[#08090A] text-[16px] block lg:hidden" />
          <p className="text-[#08090A] font-[500] cursor-pointer text-[16px] text-nowrap">
            Contact Us
          </p>
        </Link>
      </div>
      <div className="relative z-20 lg:hidden  py-2.5 me-2">
        <Link to="/AboutUs" className="flex items-center gap-x-2">
          <FaGift className="text-[#08090A] text-[16px] xl:text-xl" />
          <p className="text-[#08090A] font-[500] text-[16px] lg:text-[18px] xl:text-xl text-nowrap">
            About Us
          </p>
        </Link>
      </div>
    </>
  );
};

export default Dropdown;
