import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaTimes } from "react-icons/fa";
import { LuPaperclip } from "react-icons/lu";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../assets/svg/leftArrow.webp";
import GovernmentId from "../../components/Myprofile/GovernmentId";
import PhoneInputSeparater from "../../components/PhoneInput/PhoneInput";
import "../../components/PropertyDetails/PropertyBooking/phone.css";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import {
  useAuthUserQuery,
  useUpdateUserDataMutation,
} from "../../redux/features/auth/authApi";
import BackdropPrompt from "./BackdropPrompt";
import "./MyProfile.css";
const MyProfile = () => {
  const token = useTokenFromLocalStorage();
  const [updateUserData, { isSuccess }] = useUpdateUserDataMutation();
  const [isEdit, setIsEdit] = useState(false);
  const [customPrompt, setCustomPrompt] = useState(false);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isEmergencyContactValid, setIsEmergencyContactValid] = useState(true);
  const [isResidentialValid, setIsResidentialValid] = useState(true);
  const [isGenderValid, setIsGenderValid] = useState(true);
  const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(true);
  const [capturedImage, setCapturedImage] = useState(null);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    city: "",
    emergencyContact: "",
    dateOfBirth: null,
    gender: "",
  });

  const [originalProfileData, setOriginalProfileData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    city: "",
    emergencyContact: "",
    dateOfBirth: null,
    gender: "",
  });

  const { data } = useAuthUserQuery(token);
  const [governmentids, setGovernMentIds] = useState([]);

  const handleRemoveID = (id) => {
    setGovernMentIds(governmentids.filter((item) => item.id !== id));
  };
  useEffect(() => {
    if (data) {
      console.log(data?.user);
      setGovernMentIds(data?.user?.governmentIds || []);
      const fetchedData = {
        email: data.user.email || "",
        firstName: data.user.firstName || "",
        lastName: data.user.lastName || "",
        phoneNumber: data.user.phoneNumber || "",
        city: data.user.city || "",
        emergencyContact: data.user.emergencyContact || "",
        dateOfBirth: data.user.dateOfBirth
          ? new Date(data.user.dateOfBirth)
          : null,
        gender: data.user.gender || "",
      };
      setProfileData(fetchedData);
      setOriginalProfileData(fetchedData); // Set original profile data
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setCustomPrompt(true);
    }
  }, [isSuccess]);

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
        setIsFirstNameValid(/^[a-zA-Z]+$/.test(value.trim()));
        break;
      case "lastName":
        setIsLastNameValid(/^[a-zA-Z]+$/.test(value.trim()));
        break;
      case "emailAddress":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailRegex.test(value));
        break;
      case "phoneNumber":
        setIsPhoneNumberValid(value.replace(/[^0-9]/g, "").length === 12);
        break;
      case "emergencyContact":
        setIsEmergencyContactValid(value.replace(/[^0-9]/g, "").length === 12); // Updated to 10 characters for Indian phone numbers
        break;
      case "city":
        setIsResidentialValid(value.trim() !== "");
        break;
      case "gender":
        setIsGenderValid(value !== "");
        break;
      case "dateOfBirth":
        setIsDateOfBirthValid(value !== null);
        break;
      default:
        break;
    }
  };

  const handleEditDone = () => {
    let isValid = true;

    Object.keys(profileData).forEach((key) => {
      if (profileData[key] !== originalProfileData[key]) {
        validateField(key, profileData[key]);
        if (key === "firstName" && !isFirstNameValid) isValid = false;
        if (key === "lastName" && !isLastNameValid) isValid = false;
        if (key === "emailAddress" && !isEmailValid) isValid = false;
        if (key === "phoneNumber" && !isPhoneNumberValid) isValid = false;
        if (key === "emergencyContact" && !isEmergencyContactValid)
          isValid = false;
        if (key === "city" && !isResidentialValid) isValid = false;
        if (key === "gender" && !isGenderValid) isValid = false;
        if (key === "dateOfBirth" && !isDateOfBirthValid) isValid = false;
      }
    });

    if (isValid) {
      updateUserData({ token, userData: profileData });
      setIsEdit(false);
    }
  };

  const handleEditProfileDetails = () => {
    if (isEdit) {
      // If currently in edit mode and cancelling, reset profile data to original
      setProfileData(originalProfileData);
    }
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGenderChange = (gender) => {
    setProfileData({ ...profileData, gender });
    setIsGenderValid(gender !== "");
  };

  const navigate = useNavigate();
  const handlePhoneChange = (phone) => {
    setProfileData({ ...profileData, phoneNumber: phone });
    // setIsPhoneNumberValid(validatePhoneNumber(phone));
  };
  const handleEmergencyContactChange = (phone) => {
    setProfileData({ ...profileData, emergencyContact: phone });
    // setIsEmergencyContactValid(validateEmergencyContact(phone));
  };

  return (
    <div className="2xl:w-[856px] w-full max-sm:px-0 max-xl:px-5">
      <div className="flex flex-col w-full mx-auto">
        <div className="flex gap-5 justify-between max-sm:px-3 w-full leading-[150%] max-lg:mb-4 items-center text-zinc-950 max-md:flex-wrap max-md:max-w-full">
          <div className="flex-auto flex items-center gap-x-4 max-md:text-lg font-butler text-[32px] tracking-wide">
            <button onClick={() => navigate(-1)} className="w-7 lg:hidden">
              <img src={leftArrow} alt="Back" />
            </button>{" "}
            Profile Details
          </div>
          <div
            onClick={handleEditProfileDetails}
            className="flex gap-2 justify-between max-md:py-2 px-6 py-4 text-base font-semibold tracking-normal whitespace-nowrap rounded border border-solid border-[color:var(--black,#08090A)] max-md:px-5 cursor-pointer"
          >
            {isEdit ? (
              <button>Cancel</button>
            ) : (
              <button className="flex font-Lato text-base font-semibold items-center gap-1">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8bb1837a2b6ff7d0d99c6ce9207e66739d8efb10f9c091aa4f04a4a5836d6348?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                  className="my-auto w-4 aspect-square"
                  alt="Edit"
                />
                Edit
              </button>
            )}
          </div>
        </div>
        <hr className="lg:hidden" />
        <div className="flex gap-6 justify-between mt-10 text-base font-Lato tracking-normal leading-6 max-md:flex-wrap max-md:max-w-full max-md:px-5">
          <div className="flex flex-col flex-1">
            <div className="font-semibold text-zinc-950">First name</div>
            <input
              type="text"
              className={`justify-center items-start py-3 pr-16 pl-4 mt-2 whitespace-nowrap rounded border border-solid border-[color:var(--Grey-3,#CED4DA)] text-zinc-500 max-md:pr-5`}
              placeholder="Name"
              value={profileData.firstName}
              onChange={(e) =>
                setProfileData({ ...profileData, firstName: e.target.value })
              }
              disabled={!isEdit}
            />
            {!isFirstNameValid && (
              <p className="text-red-500 text-sm">
                Please Enter Valid First Name.
              </p>
            )}
          </div>
          <div className="flex flex-col flex-1">
            <div className="font-semibold text-zinc-950">Last name</div>
            <input
              type="text"
              className={`justify-center items-start py-3 pr-16 pl-4 mt-2 whitespace-nowrap rounded border border-solid border-[color:var(--Grey-3,#CED4DA)] text-zinc-500 max-md:pr-5`}
              placeholder="Last Name"
              value={profileData.lastName}
              onChange={(e) =>
                setProfileData({ ...profileData, lastName: e.target.value })
              }
              disabled={!isEdit}
            />
            {!isLastNameValid && (
              <p className="text-red-500 text-sm">
                Please Enter Valid Last Name.
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-5 justify-between mt-6 text-base tracking-normal max-md:flex-col leading-6 text-zinc-950 max-md:flex-wrap max-md:max-w-full max-md:px-5 items-center">
          <div className="flex flex-col flex-1">
            <div className="font-semibold mb-[0.5rem]">Phone</div>
            <PhoneInputSeparater
              PlaceHolder="Enter emergency contact"
              VALUE={profileData.phoneNumber}
              DISABLED={!isEdit}
              ID="emergencyPhone"
              NAME="phoneNumber"
              setPhone={handlePhoneChange}
              dvalue={profileData.phoneNumber}
            />
            {!isPhoneNumberValid && (
              <p className="text-red-500 text-sm">
                Please Enter Valid Phone Number.
              </p>
            )}
          </div>
          <div className="flex flex-col flex-1">
            <div className="font-semibold mb-[0.5rem]">Emergency Contact</div>
            <PhoneInputSeparater
              PlaceHolder="Enter emergency contact"
              Type="text"
              VALUE={profileData.emergencyContact}
              DISABLED={!isEdit}
              ID="emergencyContact"
              NAME="emergencyContact"
              setPhone={handleEmergencyContactChange}
              dvalue={profileData.emergencyContact}
            />
            {!isEmergencyContactValid && (
              <p className="text-red-500 text-sm">
                Please Enter Valid Emergency Contact.
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-5 justify-between mt-6 text-base tracking-normal max-md:flex-col leading-6 text-zinc-950 max-md:flex-wrap max-md:max-w-full max-md:px-5">
          <div className="flex flex-col flex-1">
            <div className="font-semibold">Email</div>
            <input
              type="email"
              className={`justify-center items-start py-3 pr-16 pl-4 mt-2 whitespace-nowrap rounded border border-solid border-[color:var(--Grey-3,#CED4DA)] text-zinc-500 max-md:pr-5`}
              placeholder="Enter email"
              value={profileData.email}
              onChange={(e) =>
                setProfileData({
                  ...profileData,
                  email: e.target.value,
                })
              }
              disabled={!isEdit}
            />
            {!isEmailValid && (
              <p className="text-red-500 text-sm">Please Enter Valid Email.</p>
            )}
          </div>

          <div className="flex flex-col flex-1">
            <div className="font-semibold">Residential City</div>
            <input
              type="text"
              className={`justify-center items-start py-3 pr-16 pl-4 mt-2 whitespace-nowrap rounded border border-solid border-[color:var(--Grey-3,#CED4DA)] text-zinc-500 max-md:pr-5`}
              placeholder="Enter city"
              value={profileData.city}
              onChange={(e) =>
                setProfileData({ ...profileData, city: e.target.value })
              }
              disabled={!isEdit}
            />
            {!isResidentialValid && (
              <p className="text-red-500 text-sm">Please Enter City.</p>
            )}
          </div>
        </div>
        <div className="flex gap-5 justify-between mt-6 text-base tracking-normal max-md:flex-col leading-6 text-zinc-950 max-md:flex-wrap max-md:max-w-full max-md:px-5">
          <div className="flex flex-col flex-1">
            <div className="font-semibold">Date of Birth</div>
            <DatePicker
              selected={profileData?.dateOfBirth}
              onChange={(date) =>
                setProfileData({ ...profileData, dateOfBirth: date })
              }
              dateFormat="dd/MM/yyyy"
              disabled={!isEdit}
              maxDate={new Date()} // Prevent future dates
              showYearDropdown // Disable year dropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown // Enable month dropdown for easier navigation
              className="justify-center items-start w-full py-3 pr-16 pl-4 mt-2 whitespace-nowrap rounded border border-solid border-[color:var(--Grey-3,#CED4DA)] text-zinc-500 max-md:pr-5"
            />

            {!isDateOfBirthValid && (
              <p className="text-red-500 text-sm">
                Please Select Date of Birth.
              </p>
            )}
          </div>
          <div className="flex flex-col flex-1">
            <div className="font-semibold">Gender</div>
            <div className="flex gap-2 mt-2">
              <button
                className={`rounded-lg border border-[#EAEFF2] py-2 px-4 ${
                  profileData.gender === "Male"
                    ? "bg-blue-600 text-white"
                    : "bg-white  text-gray-600"
                }`}
                onClick={() => handleGenderChange("Male")}
                disabled={!isEdit}
              >
                Male
              </button>
              <button
                className={`rounded-lg border border-[#EAEFF2] py-2 px-4 ${
                  profileData.gender === "Female"
                    ? "bg-blue-600 text-white"
                    : "bg-white text-gray-600"
                }`}
                onClick={() => handleGenderChange("Female")}
                disabled={!isEdit}
              >
                Female
              </button>
              <button
                className={`rounded-lg border border-[#EAEFF2] py-2 px-4 ${
                  profileData.gender === "Non-Binary"
                    ? "bg-blue-600 text-white"
                    : "bg-white text-gray-600"
                }`}
                onClick={() => handleGenderChange("Non-Binary")}
                disabled={!isEdit}
              >
                Non-Binary
              </button>
              <button
                className={`rounded-lg border border-[#EAEFF2] py-2 px-4 ${
                  profileData.gender === "Other"
                    ? "bg-blue-600 text-white"
                    : "bg-white text-gray-600"
                }`}
                onClick={() => handleGenderChange("Other")}
                disabled={!isEdit}
              >
                Other
              </button>
            </div>
            {!isGenderValid && (
              <p className="text-red-500 text-sm">Please Select Gender.</p>
            )}
          </div>
        </div>
        {governmentids.length > 0 ? (
          <>
            <h1
              className={` text-zinc-950
text-base
font-semibold
mt-6
leading-normal
tracking-tight`}
            >
              Government ID
            </h1>
            {governmentids?.map((item, index) => (
              <div
                className={`${
                  !item.fileName && "hidden"
                } "flex items-center gap-5 justify-between text-base tracking-normal max-md:flex-col leading-6 text-zinc-950 max-md:flex-wrap max-md:max-w-full max-md:px-5"`}
              >
                <div className="flex items-center  gap-2 flex-1">
                  <div className="flex text-[#617880] items-center gap-1 ">
                    <LuPaperclip className="" />
                    {item.fileName}
                  </div>
                  <button
                    className="text-red-500"
                    onClick={() => handleRemoveID(item.id)}
                  >
                    <FaTimes />
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <GovernmentId
            profileData={profileData}
            capturedImage={capturedImage}
            setCapturedImage={setCapturedImage}
          />
        )}

        {isEdit && (
          <div className="mt-6 text-right">
            <button
              onClick={handleEditDone}
              className="py-2 px-6 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
      {/* Confirmation prompt */}
      <BackdropPrompt func={setCustomPrompt} active={customPrompt} />
    </div>
  );
};

export default MyProfile;
