import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetHotelsByCitiesQuery } from "../../redux/features/futureGetaways/futureGetawaysApi";
import { useGetAllCitiesQuery } from "../../redux/features/search/getAllCities";
import Button from "../ui/Button";
import "./FutureGateWay.css";

const FutureGetawaysStepner = () => {
  const { data: cities, isLoading, isSuccess } = useGetAllCitiesQuery();
  const [isViewAll, setViewAll] = useState(false);
  const [searchCities, setSearchCities] = useState("Lucknow");
  const { data: hotelsData } = useGetHotelsByCitiesQuery(searchCities);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    // Set default locations to Lucknow
    setSearchCities("Lucknow");

    // Find the index of Lucknow and set it as the selected location
    if (cities?.data) {
      const index = Object.keys(cities.data).indexOf("Lucknow");
      setSelectedLocation(index !== -1 ? index : null);
    }
  }, [cities]);

  const handleFindHotels = (key, i) => {
    setSearchCities(key);
    setSelectedLocation(i);
  };

  return (
    <div className="px-2 lg:px-7 xl:max-w-[1296px] w-full items-center justify-center mx-auto">
      <div className="p-2">
        <div className="container mx-auto bg-white">
          <h1 className="lg:text-[32px] text-[24px] text-[#000000] font-[Butler] font-[400] mb-8">
            Plan your Future Getaways
          </h1>
        </div>
        <div className="flex w-full overflow-scroll hideScrollBar gap-12 font-[500] text-lg text-[#2F3336]">
          {cities?.data &&
            Object?.entries(cities?.data)
              ?.slice(0, 7)
              ?.map(([key, value], i) => (
                <span
                  key={i + 1}
                  className={`cursor-pointer ${
                    selectedLocation === i ? "border-b-2 border-black" : ""
                  } hover:border-b-2 hover:border-black`}
                  onClick={() => {
                    handleFindHotels(key, i);
                  }}
                >
                  {key}
                </span>
              ))}
        </div>
      </div>
      <div className="flex w-full relative">
        <div
          className={`absolute w-10 h-1 ${
            selectedLocation === 0 ? "border rounded-full text-black" : ""
          }`}
        ></div>
        <div className="w-full h-1 text-gray-100 bg-gray-100"></div>
      </div>

      <div className="overflow-x-auto overflow-y-hidden lg:overflow-hidden w-full">
        <div className="grid mt-5 xl:grid-cols-5 lg:grid-cols-4 px-2 grid-cols-1 overflow-hidden md:grid-cols-3 md:w-full gap-4">
          {isViewAll
            ? hotelsData?.map((item, i) => (
                <div key={i} className="w-full">
                  <Link to={`/propertyDetails/${item?._id}`}>
                    <div className="flex gap-4 md:mt-10">
                      <img
                        src={item?.imageUrls[0]}
                        alt="img"
                        className="mb-4 object-cover w-1/3 md:w-[86px] h-[90px] border rounded-md"
                      />
                      <div>
                        <p className="text-[#000000] font-[Butler] font-medium text-lg font-[400] leading-27">
                          {item?.name}
                        </p>
                        <p className="text-[#6D747A] font-[400] leading-6 tracking-tighter">
                          {item?.city}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            : hotelsData?.slice(0, 10)?.map((item, i) => (
                <div key={i} className="w-full">
                  <Link to={`/propertyDetails/${item?._id}`}>
                    <div className="flex gap-4 md:mt-10">
                      <img
                        src={item?.imageUrls[0]}
                        alt="img"
                        className="mb-4 object-cover w-1/3 md:w-[86px] h-[90px] border rounded-md"
                      />
                      <div>
                        <p className="text-[#000000] font-[Butler] font-medium text-lg font-[400] leading-27">
                          {item?.name}
                        </p>
                        <p className="text-[#6D747A] font-[400] leading-6 tracking-tighter">
                          {item?.city}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {!isViewAll && hotelsData?.length > 10 && (
          <div onClick={() => setViewAll(true)}>
            <Button title="View All" />
          </div>
        )}
      </div>
    </div>
  );
};

export default FutureGetawaysStepner;
