import React, { useState } from "react";
import AboutSection from "./AboutSection";

const PropertyDetailsStepner = ({ propertyOverview }) => {
  const stateData = [
    {
      text: "About",
      comp: <AboutSection propertyOverview={propertyOverview} />,
    },
    {
      text: "Amenities",
      comp: (
        <h1 className="text-[#000000] size-20 font-[Butler] max-sm:text-2xl max-lg:text-3xl px-5 lg:px-5 2xl:px-0 max-lg:-mb-3 text-4xl font-medium h-[56px]">
          Amenities
        </h1>
      ),
    },
    {
      text: "Reviews",
      comp: (
        <h1 className="text-[#000000] size-20 font-[Butler] max-sm:text-2xl max-lg:text-3xl px-5 lg:px-5 2xl:px-0 max-lg:-mb-3 text-4xl font-medium h-[56px]">
          Reviews
        </h1>
      ),
    },
    {
      text: "House Rules",
      comp: (
        <h1 className="text-[#000000] size-20 font-[Butler] max-sm:text-2xl max-lg:text-3xl px-5 lg:px-5 2xl:px-0 max-lg:-mb-3 text-4xl font-medium h-[56px]">
          House Rules
        </h1>
      ),
    },
    {
      text: "Cancellation Policy",
      comp: (
        <h1 className="text-[#000000] size-20 font-[Butler] max-sm:text-2xl max-lg:text-3xl px-5 lg:px-5 2xl:px-0 max-lg:-mb-3 text-4xl font-medium h-[56px]">
          Cancellation Policy
        </h1>
      ),
    },
    {
      text: "FAQs",
      comp: (
        <h1 className="text-[#000000] size-20 font-[Butler] max-sm:text-2xl max-lg:text-3xl px-5 lg:px-5 2xl:px-0 max-lg:-mb-3 text-4xl font-medium h-[56px]">
          FAQs
        </h1>
      ),
    },
  ];

  const [selectedLocation, setSelectedLocation] = useState(0);

  const setSelectedLocationfun = (location) => {
    setSelectedLocation(location);
  };
  return (
    <div>
      <div>
        <div className="flex  gap-12 font-Lato text-1.11xl font-semibold px-5 lg:px-5 2xl:px-0 max-sm:gap-4  w-full max-sm:overflow-x-auto leading-6 mt-8 ">
          {stateData?.map((item, i) => (
            <span
              key={i}
              className={`cursor-pointer text-[#434141] font-[Butler] text-nowrap font-[500] max-lg:text-base max-sm:text-sm text-xl 2xl:text-2xl ${
                selectedLocation === i ? "border-b-2   border-black" : ""
              } hover:border-b-2 hover:border-black `}
              onClick={() => {
                setSelectedLocationfun(i);
              }}
            >
              <a href={`#${item.text}`}>{item.text}</a>
            </span>
          ))}
        </div>
      </div>
      <div className="flex px-5 lg:px-5 2xl:px-0 max-lg:w-full w-[856px] mb-10 relative ">
        <div
          className={`absolute  w-10 h-1 ${
            selectedLocation === 0 ? "border rounded-full text-black" : ""
          }`}
        ></div>
        {/* <div className="w-full h-1 text-gray-100 bg-gray-100"></div> */}
      </div>

      {/* {stateData[selectedLocation].comp} */}
    </div>
  );
};

export default PropertyDetailsStepner;
