import React, { useState } from "react";
import mealPlanImage1 from "../../assets/images/property-d-meal-image-1.webp";
import mealPlanImage2 from "../../assets/images/property-d-meal-image-2.webp";
import mealPlanImage3 from "../../assets/images/property-d-meal-image-3.webp";
import Button from "../ui/Button";
import rightArrow from "../../assets/svg/chevron-right.webp";
import MealPlansModal from "../modals/MealPlansModal";
import MealPlansModalContent from "./SimilarProperties/MealPlansModalContent";

const MealPlans = ({ hotelMealPlans, hotelCity, hotelName, hotelAddress }) => {
  const [IsMealPlan, setIsMealPlan] = useState(false);
  const isOpenMealPlans = () => {
    setIsMealPlan(true);
  };
  const isCloseMealPlans = () => {
    setIsMealPlan(false);
  };
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Check out ${hotelName}`,
          text: `${hotelName} located at ${hotelAddress}, ${hotelCity}`,
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.error("Error sharing:", err);
        });
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };

  return (
    <div className="mt-[72px] w-full">
      <h1 className="text-[36px] px-4 lg:px-5 2xl:px-0 max-sm:text-xl font-[500] font-[Butler]">
        Meal Plans
      </h1>

      <div className="flex items-center max-md:gap-3 lg:gap-[24px] lg:mt-[24px] px-4 lg:px-5 2xl:px-0 w-full overflow-hidden">
        <img
          src={mealPlanImage1}
          alt="meal"
          className="cursor-pointer w-[240px] max-sm:w-[165px]"
        />
        <img
          src={mealPlanImage2}
          alt="meal"
          className="cursor-pointer w-[240px] max-sm:w-[165px]"
        />
        <img
          src={mealPlanImage3}
          alt="meal"
          className="cursor-pointer w-[240px] max-sm:hidden"
        />
      </div>

      <ul className="lg:mt-[24px] px-4 lg:px-5 2xl:px-0 list-disc flex flex-col gap-y-[12px] ml-4 text-[18px] max-md:text-sm text-[#08090A]">
        {Array.isArray(hotelMealPlans) && hotelMealPlans.length > 0 ? (
          hotelMealPlans.map((item, i) => <li key={i}>{item}</li>)
        ) : (
          <li>No meal plans available</li>
        )}
      </ul>

      <div className="mt-[40px] px-1 max-lg:px-4 lg:px-5 2xl:px-0 flex items-center max-lg:gap-1 lg:gap-[24px] w-full">
        <button className="w-1/2 h-12 px-6 py-4 rounded border border-zinc-950 md:max-w-[190px] lg:hidden justify-center items-center gap-2 flex">
          <div className="text-zinc-950 text-base font-medium text-nowrap max-sm:text-sm leading-none">
            Download Menu
          </div>
          <div className="w-3.5 h-3.5 flex items-center relative">
            <img src={rightArrow} alt="" />
          </div>
        </button>
        <div className="max-lg:hidden">
          <Button title="Download Menu" icon={rightArrow} bgColor="#000000" />
        </div>
        <div onClick={isOpenMealPlans} className="max-sm:w-1/2">
          <button className="h-12 w-full py-4 rounded border border-zinc-950 lg:hidden justify-center items-center gap-2 inline-flex md:w-[190px]">
            <div className="text-zinc-950 text-base font-medium leading-none text-nowrap max-sm:text-sm">
              View More Details
            </div>
            <div className="w-3.5 h-3.5 relative">
              <img src={rightArrow} alt="" />
            </div>
          </button>
          <div className="max-lg:hidden">
            <Button title="View More Details" icon={rightArrow} />
          </div>
        </div>
      </div>

      {IsMealPlan && (
        <MealPlansModal
          children={
            <MealPlansModalContent
              handleShare={handleShare}
              hotelMealPlans={hotelMealPlans}
            />
          }
          onCloseModale={isCloseMealPlans}
        />
      )}
    </div>
  );
};

export default MealPlans;
