import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseURL } from "../../../../redux/config";
import { useGetAllCitiesQuery } from "../../../../redux/features/search/getAllCities";
import DateRange from "../../../Banner/SearchBar/DateRange";
import PickLocation from "../../../Banner/SearchBar/PickLocation";
import PickRooms from "../../../Banner/SearchBar/PickRooms";

const Form = ({ titleDescription }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("");
  const locationState = useLocation();
  const [isLocationEmpty, setIsLocationEmpty] = useState(false);
  const [ShowDateRange, setShowDateRange] = useState(false);
  const CitiesData = useGetAllCitiesQuery(token);
  const [room, setRoom] = useState(0);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [isRoomEmpty, setIsRoomEmpty] = useState(false);
  const roomsGuests = [
    {
      heading: "Adults",
      subHeading: "Ages 13 or above",
      count: adults,
      handler: setAdults,
    },
    {
      heading: "Children",
      subHeading: "Below the age of 5",
      count: children,
      handler: setChildren,
    },
    {
      heading: "Rooms",
      subHeading: "",
      count: room,
      handler: setRoom,
    },
  ];
  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      setTimeout(() => {
        setShowDateRange(false);
      }, 500);
    }
  }, [checkInDate, checkOutDate]);
  useEffect(() => {
    console.log(checkInDate, checkOutDate);
    if (CitiesData && CitiesData?.data) {
      const citiesObj = CitiesData?.data?.data;
      const cities = Object.keys(citiesObj);
      const list = cities?.map((city, idx) => {
        const cityName = city;

        const cityObj = {
          id: idx,
          name: cityName,
        };
        return cityObj;
      });
      setLocationList(list);
    }
  }, [CitiesData, CitiesData?.data, checkInDate, checkOutDate]);
  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };
  useEffect(() => {
    getTokenFromLocalStorage();
  }, []);
  const [noMatch, setNoMatch] = useState(false);
  const handleDateFormat = (date) => {
    let day = date?.getDate();
    let month = date?.getMonth();
    let year = date?.getFullYear();
    let formattedDate =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;
    return formattedDate;
  };
  const navigateTo = (searchProp) => {
    navigate("/allproperties", {
      state: {
        searchData: searchProp,
        location: location,
        checkIn: checkInDate,
        checkOut: checkOutDate,
        hotelType: locationState?.state?.hotelType,
        from: locationState?.pathname,
      },
    });
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${BaseURL}/hotels/search?city=${location}&hotelType=${
          locationState?.state?.hotelType
        }&checkIn=${handleDateFormat(checkInDate)}&checkOut=${handleDateFormat(
          checkOutDate
        )}&noOfRooms=${room}&guests=${adults + children}&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response.json();
      if (location.trim() === "") {
        setIsLocationEmpty("Please enter valid information ");
        return;
      }
      console.log("response data is : ", responseData);
      navigateTo(responseData);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };
  return (
    <div>
      <div className=" w-full">
        <div className="w-full   flex-col justify-start items-start gap-6 inline-flex"></div>
        <PickLocation
          setShowDateRange={setShowDateRange}
          locationList={locationList}
          location={location}
          setLocation={setLocation}
          isLocationEmpty={isLocationEmpty}
          setIsLocationEmpty={setIsLocationEmpty}
          noMatch={noMatch}
          setNoMatch={setNoMatch}
        />
        <div className="mt-6 flex gap-3">
          <div
            onClick={() => setShowDateRange(!ShowDateRange)}
            className="relative cursor-pointer w-full  "
          >
            <div className="text-zinc-950 text-base  leading-normal tracking-tight mb-2">
              Check In
            </div>
            <div className=" flex relative  w-full justify-center ">
              <div
                type="text"
                placeholder="Select Date"
                placeholderText="Select Date"
                className="border w-full border-gray-300 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700
            text-base
            
            leading-[30px]"
              >
                {checkInDate !== null
                  ? format(checkInDate, "dd/MM/yyyy")
                  : "dd/mm/yyyy"}
              </div>{" "}
            </div>
          </div>
          <div
            onClick={() => setShowDateRange(!ShowDateRange)}
            className="relative cursor-pointer   w-full  "
          >
            <div className="text-zinc-950 text-base  leading-normal mb-2 tracking-tight">
              Check Out
            </div>
            <div className=" flex relative  w-full justify-center ">
              <div
                type="text"
                placeholder="Select Date"
                placeholderText="Select Date"
                className="border w-full border-gray-300 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700
            text-base
            
            leading-[30px]"
              >
                {checkOutDate !== null
                  ? format(checkOutDate, "dd/MM/yyyy")
                  : "dd/mm/yyyy"}
              </div>{" "}
            </div>
          </div>
          {ShowDateRange && (
            <div
              // onMouseLeave={() => setShowDateRange(false)}
              className="absolute max-lg:top-[229px] lg:top-[300px] right-0 left-2    w-full z-40  scale-90"
            >
              <DateRange
                checkInDate={checkInDate}
                ShowDateRange={ShowDateRange}
                setCheckInDate={setCheckInDate}
                checkOutDate={checkOutDate}
                setCheckOutDate={setCheckOutDate}
                setShowDateRange={setShowDateRange}
              />
            </div>
          )}
        </div>
        <div className="text-zinc-950 text-base font-semibold leading-normal tracking-tight mt-6 mb-2">
          Guests
        </div>
        <div className="w-full ">
          <PickRooms
            ShowDateRange={setShowDateRange}
            roomsGuests={roomsGuests}
            guests={children + adults}
            room={room}
            isRoomEmpty={isRoomEmpty}
            setIsRoomEmpty={setIsRoomEmpty}
          />
        </div>
        <button
          className="w-full  h-[56px] bg-[#08090A] text-[#FFFFFF] mx-auto text-[16px] font-[700] my-4 py-[16px] px-[24px] rounded-[4px]"
          onClick={() => {
            handleSearch();
          }}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Form;
