import { React } from "react";
import map_Img_gray from "../../.././assets/svg/MapGray.webp";
import mapBlue from "../../../assets/svg/mapBlue.webp";
const API_KEY = "AIzaSyAow-AUe2Ouc-HX0NkAOXlLXDQhkqNMehg";

const Map = () => {
  const handleGetDirections = () => {
    const destination = encodeURIComponent("North Goa, Goa");
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
    window.open(mapsUrl, "_blank");
  };

  return (
    <>
      <h1 className="text-[36px] max-md:text-2xl xl:hidden font-[500] font-[Butler] px-5 lg:px-5 2xl:px-0 flex-col flex items-start justify-between max-md:py-6">
        Location
        <div className="w-full px-5 lg:px-5 2xl:px-0 border border-[#CED4DA]"></div>
      </h1>

      <p className="flex gap-2 items-center px-4 lg:px-5 text-xl my-4">
        <img
          src={map_Img_gray}
          className="text-[#6D747A] w-18 h-22 leading-6"
          alt="map_Img_gray"
        />
        <span className="text-[#08090A] max-md:text-lg font-[400]">
          North Goa, Goa
        </span>
      </p>

      <div className="px-4 lg:px-5" style={{ position: "relative" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d492042.2256915526!2d73.65011245352616!3d15.533847498344317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc0ed52faa031%3A0x1bcd0a8c7ed54dd0!2sNorth%20Goa%2C%20Goa!5e0!3m2!1sen!2sin!4v1708667027154!5m2!1sen!2sin"
          style={{
            border: "0",
            maxWidth: "100%",
            position: "relative",
          }}
          className="max-xl:w-full h-[450px] max-md:h-[243px] xl:w-[600px]"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <button
          className="w-[193px] h-14 px-6 py-4 bg-white rounded border absolute bottom-5 right-20 border-blue-600 justify-center items-center gap-2.5 flex"
          onClick={handleGetDirections}
        >
          <div className="text-blue-600 text-base font-semibold leading-normal">
            Get Directions
          </div>
          <div className="w-4 h-4 relative">
            <img src={mapBlue} className="w-4 h-4" alt="" />
          </div>
        </button>
      </div>
    </>
  );
};

export default Map;
