import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../LuxuryVillas/LuxuryVillas.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import scrollLeftActive from "../../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../../assets/svg/scroll-left.webp";
import scrollRight from "../../../assets/svg/scroll-right.webp";
import locationIcon from "../../../assets/svg/search-map-pin.webp";

import { BaseURL } from "../../../redux/config";
import { useGetHotelsByCitiesQuery } from "../../../redux/features/futureGetaways/futureGetawaysApi";
import { useGetCitiesQuery } from "../../../redux/features/home/homeApi";
import Card from "./Card";

const Destination = ({ token }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [searchCities, setSearchCities] = useState("");
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { data: hotelsData } = useGetHotelsByCitiesQuery(searchCities);
  const { data } = useGetCitiesQuery();
  const cities = data?.data;

  const handlePickShorting = (key) => {
    setSearchCities(key);
  };

  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const navigateTo = (responseJson) => {
    navigate("/allproperties", {
      state: {
        searchData: responseJson,
        location: "",
        checkIn: "",
        checkOut: "",
      },
    });
  };

  const viewAllProperties = async () => {
    const data = await fetch(`${BaseURL}/hotels/search`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const responseJson = await data.json();
    navigateTo(responseJson);
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  }, [hotelsData]);

  return (
    <>
      <div className="xl:max-w-[1296px] w-full flex items-center justify-center flex-col mt-10 mx-auto">
        <div className="flex px-2 lg:px-7 items-center w-full justify-between">
          <p className="md:text-[32px] text-2xl text-[#000000] font-[Butler] font-[400]">
            Pick a Destination
          </p>
          <div onClick={viewAllProperties}>
            <a href="#" className="text-[#0F6DDC] text-[18px] font-[700]">
              VIEW ALL
            </a>
          </div>
        </div>
        <div className="flex gap-x-[12px] lg:gap-x-0 flex-col md:flex-row">
          <div className="lg:w-[280px] xl:w-[300px] md:w-[250px] h-[390px] mt-[36px] w-full overflow-y-scroll mx-auto">
            <div className="scroll-auto">
              {cities &&
                Object.entries(cities)?.map(([key, value]) => (
                  <div
                    onClick={() => handlePickShorting(key)}
                    className="flex items-center justify-between py-[10px] px-[10px] hover:bg-[#F7F7F7] cursor-pointer rounded-md"
                    key={key}
                  >
                    <div className="flex items-center gap-[10px]">
                      <img src={locationIcon} alt="icon" />
                      <span className="text-[16px] text-[#08090A]">{key}</span>
                    </div>
                    <div>
                      <span className="text-[14px] text-[#434141]">
                        {value || 1071} properties
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="lg:ml-[11px] mt-[36px]">
            <div className="xl:w-[950px] mx-auto w-[370px] md:w-[450px] lg:w-[700px]">
              <Swiper
                ref={swiperRef}
                navigation
                pagination={{ clickable: true }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                  handleSlideChange();
                }}
                breakpoints={{
                  1440: { slidesPerView: 3 },
                  1024: { slidesPerView: 3 },
                  768: { slidesPerView: 2 },
                  368: { slidesPerView: 1 },
                }}
                spaceBetween={30}
                className=""
                onSlideChange={handleSlideChange}
              >
                {hotelsData?.map((property) => (
                  <SwiperSlide className="" key={property?._id}>
                    <Card propertyData={property} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="flex items-center justify-end mt-[32px] gap-[16px] md:px-5 px-2">
              <button onClick={swipePrev} disabled={isBeginning}>
                <img
                  src={isBeginning ? scrollLeft : scrollLeftActive}
                  alt="scroll"
                />
              </button>
              <button onClick={swipeNext} disabled={isEnd}>
                <img src={scrollRight} alt="scroll" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Destination;
