import React, { useState } from "react";
import iconBottom from "../../../assets/images/Faqs/bottom.webp";
import search from "../../../assets/images/Faqs/search.webp";
import iconTop from "../../../assets/images/Faqs/topIcon.webp";
import chevronup from "../../../assets/svg/chevronup.webp";
const Faqs = ({ faqData }) => {
  const [isToggleIcons, setIsToggleIcons] = useState(
    new Array(faqData?.length).fill(false)
  );
  const [searchKeyword, setSearchKeyword] = useState(""); // State for search keyword

  const toggleIcon = (index) => {
    const newToggleIcons = [...isToggleIcons]; // Create a copy of the state array
    newToggleIcons[index] = !isToggleIcons[index];
    setIsToggleIcons(newToggleIcons);
  };
  const [showHouseRules, setShowHouseRules] = useState(false);
  const filteredFaqData = faqData?.filter((item) =>
    item?.question?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
  );

  return (
    <div
      className="xl:max-w-[1296px] w-full  items-center justify-center mx-auto px-5 lg:px-5 2xl:px-0"
      id="FAQs"
      style={{ scrollMarginTop: "100px" }}
    >
      <h1
        onClick={() => setShowHouseRules(!showHouseRules)}
        className="text-[36px] max-md:text-2xl font-[500] font-[Butler] flex items-center justify-between max-md:py-6"
      >
        FAQs
        <img src={chevronup} className="max-md:block w-6 h-6 hidden" alt="" />
      </h1>
      <div className="w-full border border-[#CED4DA]"></div>
      <div
        className={`mt-[40px] ${showHouseRules ? "block" : "max-md:hidden"}`}
      >
        <div className="relative">
          <input
            type="text"
            value={searchKeyword} // Bind value to searchKeyword state
            onChange={(e) => setSearchKeyword(e.target.value)} // Handle input change
            className="w-[416px] max-md:w-full h-[48px] pl-16 pr-8 border border-gray-100 rounded-full bg-gray-100 text-neutral-700 text-lg font-medium  leading-[27px] outline-none"
            placeholder="Search"
          />
          <img
            src={search}
            alt="search"
            className="absolute left-4 top-1/2 transform -translate-y-1/2"
          />
        </div>
      </div>

      <div className={` w-full ${showHouseRules ? "block" : "max-md:hidden"}`}>
        {filteredFaqData?.map((item, index) => (
          <div key={index}>
            <div
              onClick={() => toggleIcon(index)}
              className="flex cursor-default justify-between h-[40px] py-8 px-0 mb-4 "
            >
              <div className="text-zinc-950 text-base font-semibold max-md:text-sm leading-normal tracking-tight">
                {item.question}
              </div>
              <div>
                <img
                  src={isToggleIcons[index] ? iconBottom : iconTop}
                  className="w-6 max-md:w-[17.61]"
                  alt=""
                />
              </div>
            </div>
            <div
              className="w-[746px] max-md:w-full"
              style={{ display: isToggleIcons[index] ? "block" : "none" }}
            >
              {item.answer}
            </div>
            <hr />
          </div>
        ))}
        <div className="w-[746px] max-md:w-full h-px border-slate-200 transform -rotate-0"></div>
      </div>
    </div>
  );
};

export default Faqs;
