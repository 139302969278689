import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const PropertyDetailsModal = ({
  SetPropertyDetails,
  setPropertyDetailsModal,
}) => {
  const BedroomData = ["Bedroom", "Living room", "Kitchen", "Washroom"];
  const FloorData = [
    "Ground floor",
    "First floor",
    "Second floor",
    "Third floor",
  ];
  const BedRoomSizeData = [
    "Twin (39”x75”)",
    "Twin XL (39”x80”)",
    "Double (54”x75”)",
    "Queen (60”x80”)",
    "King (76”x80”)",
  ];
  const BathroomData = ["Yes", "No"];

  const [rooms, setRooms] = useState([
    {
      bedroom: "",
      floor: "",
      size: "",
      bathroom: "",
      showBedRoom: false,
      showFloor: false,
      showBedRoomSize: false,
      showBathroom: false,
    },
  ]);

  const handleAddRoom = () => {
    setRooms([
      ...rooms,
      {
        bedroom: "",
        floor: "",
        size: "",
        bathroom: "",
        showBedRoom: false,
        showFloor: false,
        showBedRoomSize: false,
        showBathroom: false,
      },
    ]);
  };

  const handleSave = () => {
    SetPropertyDetails(rooms);
    setPropertyDetailsModal(false);
  };

  const handleChange = (index, field, value) => {
    const newRooms = [...rooms];
    newRooms[index][field] = value;
    setRooms(newRooms);
  };

  const handleToggleDropdown = (index, field) => {
    const newRooms = [...rooms];
    newRooms[index][field] = !newRooms[index][field];
    setRooms(newRooms);
  };

  const renderDropdown = (index, showState, setShowState, data, field) => (
    <div className="h-12 w-full flex-col justify-start items-start gap-2 flex">
      <div className="w-full h-12 flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-12 flex-col justify-start w-full relative items-end gap-1 flex">
          <button
            onClick={() => handleToggleDropdown(index, setShowState)}
            className="self-stretch h-12 px-4 py-3 rounded border w-full border-gray-300 justify-between items-center inline-flex"
          >
            <div className="text-zinc-500 text-base font-normal font-Lato w-full leading-normal text-start tracking-tight">
              {rooms[index][field] ||
                field.charAt(0).toUpperCase() + field.slice(1)}
            </div>
            <div className="w-6 h-6 relative">
              <FaAngleDown className="text-2xl" />
            </div>
          </button>
          {showState && (
            <div className="absolute w-full items-start justify-start z-50 left-0 bg-white shadow rounded top-12 flex flex-col">
              {data?.map((x, i) => (
                <button
                  className="w-full py-2 hover:bg-gray-200 flex justify-start px-4"
                  key={i}
                  onClick={() => {
                    handleChange(index, field, x);
                    handleToggleDropdown(index, setShowState);
                  }}
                >
                  {x}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black/30 z-50 example overflow-scroll flex justify-center items-center">
      <div className="w-[424px] h-[602px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
        <div className="h-[30px] flex-col justify-start items-start gap-12 flex">
          <div className="w-[376px] justify-start items-center gap-[82px] inline-flex">
            <div className="w-6 h-6 relative" />
            <div className="w-[164px] text-zinc-950 text-xl font-bold font-Lato leading-[30px]">
              Property details
            </div>
            <div className="w-6 h-6 relative" />
          </div>
        </div>

        <div className="self-stretch overflow-y-auto h-[316px] flex-col justify-start items-center gap-6 flex">
          {rooms?.map((room, index) => (
            <div
              key={index}
              className="self-stretch flex-col justify-start items-center gap-6 flex"
            >
              <div className="self-stretch justify-center items-start gap-6 inline-flex">
                <div className="grow shrink basis-0 text-zinc-950 text-lg font-bold font-Lato leading-7">
                  Room {index + 1}{" "}
                </div>
                <div className="bg-white rounded-lg justify-start items-start gap-2.5 flex">
                  <button
                    onClick={handleAddRoom}
                    className="text-blue-600 text-sm font-bold font-Lato uppercase leading-[21px]"
                  >
                    + Add room
                  </button>
                </div>
              </div>
              {renderDropdown(
                index,
                room.showBedRoom,
                "showBedRoom",
                BedroomData,
                "bedroom"
              )}
              {renderDropdown(
                index,
                room.showFloor,
                "showFloor",
                FloorData,
                "floor"
              )}
              {renderDropdown(
                index,
                room.showBedRoomSize,
                "showBedRoomSize",
                BedRoomSizeData,
                "size"
              )}
              {renderDropdown(
                index,
                room.showBathroom,
                "showBathroom",
                BathroomData,
                "bathroom"
              )}
            </div>
          ))}
        </div>
        <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
          <button
            onClick={handleSave}
            className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
          >
            <div className="text-white text-base font-bold font-Lato leading-none">
              Save
            </div>
          </button>

          <div
            role="button"
            onClick={() => setPropertyDetailsModal(false)}
            className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsModal;
