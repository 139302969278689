import React from "react";
import cover from "../../assets/images/EventsAndExp/cover.webp";
import EventSearchBar from "./EventSearchBar";

const Banner = (props) => {
  return (
    <div className="relative">
      <div
        style={{
          backgroundImage: `url(${cover})`,
        }}
        className="min-h-[537px] flex justify-center bg-cover bg-center items-center"
      >
        <h1 className="font-butler font-bold text-[56px] leading-[67.2px] text-white">
          Crafting Unforgettable Moments
        </h1>
      </div>
      {/* <div className="absolute -bottom-[30%] min-w-[1250px] left-[50%] w-[90%] -translate-x-[50%]">
        <SearchBar />
      </div> */}
      <EventSearchBar
        city={props.city}
        formVal={props.formVal}
        func={props.func}
        form={props.form}
      />
    </div>
  );
};

export default Banner;
