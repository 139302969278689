import email from "../../../assets/svg/email.webp";
import locationPin from "../../../assets/svg/locationPin.webp";
import phone2 from "../../../assets/svg/phone2.webp";
import RequestForm from "../../ContactUs/FormSection/RequestForm";

const FormSection = (props) => {
  return (
    <div 
      className={
        props.alt
          ? "px-10 2xl:px-[72px] h-fit mb-[220px] md:mb-0  "
          : "mx-4 2xl:px-[2px] sm:h-[520px] h-[1300px] flex flex-col-reverse md:mb-20"
      }
    >
      <div className="font-[butler]">
        <div className="flex gap-x-6 mt-[102px] sm:ms-[108px] ">
          <img className="w-[68px] h-[68px]" src={phone2} alt="" />
          <div className="w-[329px] flex h-[94px] flex-col justify-start items-start gap-2 ">
            <div className="text-zinc-500 text-base font-medium  leading-normal">
              Have any question?
            </div>
            <div className="self-stretch justify-start items-start gap-[72px] inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-lg font-normal  leading-[27px] tracking-tight">
                  9819040745
                </div>
                <div className="text-zinc-950 text-lg font-normal  leading-[27px] tracking-tight">
                  9821392630
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-x-6 mt-[48px] sm:ms-[108px]">
          <img className="w-[68px] h-[68px]" src={email} alt="" />
          <div className="w-[329px] flex h-[94px] flex-col justify-start items-start gap-2 ">
            <div className="text-zinc-500 text-base font-medium  leading-normal">
              Write email
            </div>
            <div className="self-stretch justify-start items-start gap-[72px] inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                info@ukiyostays.com
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-x-6 mt-[48px] sm:ms-[108px]">
          <img className="w-[68px] h-[68px]" src={locationPin} alt="" />
          <div className="w-[329px] flex h-[94px] flex-col justify-start items-start gap-2 ">
            <div className="text-zinc-500 text-base font-medium  leading-normal">
              Visit anytime
            </div>
            <div className="self-stretch justify-start items-start gap-[72px] inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                1306,13th Floor, Signature Business Park, Postal Colony Road,
                Chembur, Mumbai- 400071
              </div>
            </div>
          </div>
        </div>
      </div>

      <RequestForm />
    </div>
  );
};

export default FormSection;
