import { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { useSelector } from "react-redux";
import AddANoteModal from "../../components/Ical/AddANoteModal";
import BasePriceModalTwo from "../../components/Ical/BasePriceModalTwo";

const CalenderSidebarTwo = () => {
  const defaultDateSliceValue = useSelector((state) => state.DefaultDate.value);
  const [showBasePriceModal, setShowBasePriceModal] = useState(false);
  const [defaultBasePriceValue, setDefaultBasePriceValue] = useState(1299);
  const [ShowAddANoteModal, setAddANoteModal] = useState(false);
  useEffect(() => {
    console.log(defaultBasePriceValue);
  }, [defaultBasePriceValue]);
  return (
    <div className="w-[306px] pe-4 border-e-2 h-full pt-4">
      <div className="text-black text-2xl font-semibold font-Lato leading-9 tracking-tight">
        {new Date(defaultDateSliceValue.DefaultDate).toLocaleDateString(
          "en-GB",
          { day: "numeric", month: "long" }
        )}
      </div>
      <div className="h-[30px] mt-7  items-center justify-between w-full flex">
        <div className="text-black text-xl font-normal font-Lato leading-[30px] tracking-tight">
          Base Price
        </div>
        <div className="text-black text-sm font-medium cursor-pointer font-Lato underline leading-[21px] tracking-tight">
          EDIT
        </div>
      </div>
      {/* per night price */}
      <button
        onClick={() => setShowBasePriceModal(true)}
        className="w-full cursor-pointer h-[86px] p-4 bg-white my-4 rounded border border-[#ced4da] flex-col justify-start items-start inline-flex"
      >
        <div className="text-black text-2xl font-semibold font-Lato leading-9 tracking-tight">
          ₹{defaultBasePriceValue}
        </div>
        <div className="text-[#6c747a] text-xs font-normal font-Lato leading-[18px]">
          per night
        </div>
      </button>
      {/* Add a note */}
      <div
        onClick={() => setAddANoteModal(true)}
        className="w-full mb-4 h-[53px] p-4 bg-white rounded border border-[#ced4da] cursor-pointer  items-center justify-between flex"
      >
        <div className="text-[#434141] text-sm font-normal font-Lato leading-[21px]">
          Add a note
        </div>
        <div className="w-[18px] h-[18px] relative">
          <FaAngleRight />
        </div>
      </div>
      <button className="w-72 h-12 px-6 py-4 bg-[#08090a] rounded border flex-col  justify-center items-center gap-2.5 inline-flex">
        <div className="text-white text-base font-bold font-Lato leading-none">
          Block night
        </div>
      </button>
      {showBasePriceModal && (
        <BasePriceModalTwo
          setShowBasePriceModal={setShowBasePriceModal}
          defaultBasePriceValue={defaultBasePriceValue}
          setDefaultBasePriceValue={setDefaultBasePriceValue}
        />
      )}
      {ShowAddANoteModal && (
        <AddANoteModal
          date={new Date(defaultDateSliceValue.DefaultDate).toLocaleDateString(
            "en-GB",
            { day: "numeric", month: "long" }
          )}
          setShowAddANoteModal={setAddANoteModal}
        />
      )}
    </div>
  );
};

export default CalenderSidebarTwo;
