import React, { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RectangleSlider from "../../../../assets/images/Rectangle 8766.webp";
import rightArrow from "../../../../assets/svg/rightArrow.webp";
import "./slider.css";

import useTokenFromLocalStorage from "../../../../hooks/useGetToken";
import { useLogInMutation } from "../../../../redux/features/auth/authApi";
import Form from "../Form";

const banner = [
  { slideImages: RectangleSlider },
  { slideImages: RectangleSlider },
  { slideImages: RectangleSlider },
  { slideImages: RectangleSlider },
];

const Slider = ({ isHomeScreen }) => {
  const token = useTokenFromLocalStorage();
  const [logIn, { data, isLoading, isSuccess, isError, error }] =
    useLogInMutation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const innerSwiperRef = useRef(null);

  useEffect(() => {
    if (innerSwiperRef.current && innerSwiperRef.current.swiper) {
      const swiperInstance = innerSwiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);

  return (
    <div className="relative max-lg:mb-[56px] mb-[237px]">
      <div className="overflow-x-hidden max-lg:max-h-[442px] max-lg:mb-0 mb-[237px] h-[602px] relative">
        <div className="w-screen mx-auto  h-full">
          <Swiper
            ref={swiperRef}
            modules={[Pagination, Autoplay, A11y, Navigation]}
            slidesPerView={1}
            loop={false}
            autoplay={{ delay: 10 }}
            pagination={{ clickable: true }}
            speed={3000}
            navigation
            onSwiper={(swiper) => {
              if (prevRef.current && nextRef.current && swiper.navigation) {
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
            className="h-full flex justify-center items-center"
          >
            {banner?.map((item, index) => (
              <SwiperSlide
                className="h-full flex justify-center items-center"
                key={index}
              >
                <div className="relative w-full h-full flex justify-center">
                  <img
                    src={item.slideImages}
                    className="w-full min-h-[442px] h-full object-cover lg:rounded-lg"
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="bottom-6 max-lg:bottom-10 max-md:right-0 right-4 z-40 absolute h-[110px] justify-start items-start flex gap-x-6">
            {/* /// */}

            <h1>nn</h1>

            {/* ///// */}
            <div className="max-lg:hidden flex-col flex h-full items-end gap-y-2">
              <div className="w-6 h-6 relative bg-white mt-auto flex justify-center items-center cursor-pointer">
                <img
                  className="rotate-180"
                  src={rightArrow}
                  alt="Previous Slide"
                />
              </div>
              <div className="w-6 h-6 relative bg-white flex justify-center items-center cursor-pointer">
                <img src={rightArrow} alt="Next Slide" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form />
    </div>
  );
};

export default Slider;
