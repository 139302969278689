import React, { useState, useEffect } from "react";
import iconBottom from "../../assets/images/Faqs/bottom.webp";
import search from "../../assets/images/Faqs/search.webp";
import iconTop from "../../assets/images/Faqs/topIcon.webp";

const FaqsComp = (props) => {
  const faqData = props.data;


  useEffect(()=>console.log(props.data))
  // const { data: faqData, isLoading, isSuccess } = useGetFaqDataQuery();

  // const [faqData] = useState([
  //   {
  //     id: 1,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked..",
  //   },
  //   {
  //     id: 2,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  //   {
  //     id: 3,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  //   {
  //     id: 4,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  //   {
  //     id: 5,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  //   {
  //     id: 6,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  //   {
  //     id: 7,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  //   {
  //     id: 8,
  //     question:
  //       "Can I book only one or two rooms, or do I need to book the entire Home?",
  //     answer:
  //       "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
  //   },
  // ]);

  const [isToggleIcons, setIsToggleIcons] = useState(
    new Array(faqData?.length).fill(false)
  );
  const [searchKeyword, setSearchKeyword] = useState(""); // State for search keyword

  const toggleIcon = (index) => {
    const newToggleIcons = [...isToggleIcons]; // Create a copy of the state array
    newToggleIcons[index] = !isToggleIcons[index];
    setIsToggleIcons(newToggleIcons);
  };

  const [showHouseRules, setShowHouseRules] = useState(false);
  const filteredFaqData = faqData?.filter((item) =>
    item?.question?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
  );

  return (
    <div className="xl:max-w-[1296px] w-full items-center justify-center mx-auto px-5 lg:px-5 2xl:px-0">
      <div
        className={`mt-[64px] ${showHouseRules ? "block" : "max-md:hidden"}`}
      >
        <div className="relative">
          <input
            type="text"
            value={searchKeyword} // Bind value to searchKeyword state
            onChange={(e) => setSearchKeyword(e.target.value)} // Handle input change
            className="w-[416px] max-md:w-full h-[48px] pl-16 pr-8 border border-gray-100 rounded-full bg-gray-100 text-neutral-700 text-lg font-medium leading-[27px] outline-none"
            placeholder="Search"
          />
          <img
            src={search}
            alt="search"
            className="absolute left-4 top-1/2 transform -translate-y-1/2"
          />
        </div>
      </div>

      <div className={`w-full ${showHouseRules ? "block" : "max-md:hidden"}`}>
        {filteredFaqData && filteredFaqData.length > 0 ? (
          filteredFaqData.map((item, index) => (
            <div key={index} onClick={() => toggleIcon(index)}>
              <div className="flex justify-between h-[40px] py-8 px-0 mb-4">
                <div className="text-zinc-950 text-base font-semibold max-md:text-sm leading-normal tracking-tight">
                  {item.question}
                </div>
                <div>
                  <img
                    loading="lazy"
                    src={isToggleIcons[index] ? iconBottom : iconTop}
                    className="w-6 max-md:w-[17.61px]"
                    alt=""
                    
                  />
                </div>
              </div>
              <div
                className="w-[746px] max-md:w-full"
                style={{ display: isToggleIcons[index] ? "block" : "none" }}
              >
                {item.answer}
              </div>
              <hr />
            </div>
          ))
        ) : (
          <div className="text-zinc-950 mt-2 ml-3 text-base font-semibold max-md:text-sm leading-normal tracking-tight">
            No results found
          </div>
        )}
        <div className="w-[746px] max-md:w-full h-px border-slate-200 transform -rotate-0"></div>
      </div>
    </div>
  );
};

export default FaqsComp;
