import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLocation } from "react-router-dom";
import "./DateRange.css";

const DateRange = ({
  setShowDateRange,
  checkOutDate,
  setCheckOutDate,
  setCheckInDate,
  checkInDate,
  checkOutDate2,
  setCheckOutDate2,
  setCheckInDate2,
  checkInDate2,
  ShowDateRange,
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [monthShow, setMonthShow] = useState(1);

  const updateWindowWidth = () => {
    if (window.innerWidth < 760) {
      setMonthShow(1);
    } else {
      setMonthShow(2);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/luxuryproperties") {
      setMonthShow(1);
    } else {
      updateWindowWidth();
    }
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      setState([
        {
          startDate: new Date(checkInDate),
          endDate: new Date(checkOutDate),
          key: "selection",
        },
      ]);
    }
  }, [ShowDateRange, checkInDate, checkOutDate]);

  const handleFlexibleClick = () => {
    const startDate = new Date();
    const endDate = addDays(startDate, 3);
    setState([{ startDate, endDate, key: "selection" }]);
    setCheckInDate(startDate);
    setCheckOutDate(endDate);
    setCheckInDate2(format(startDate, "MMMM/dd/yyyy"));
    setCheckOutDate2(format(endDate, "MMMM/dd/yyyy"));
  };

  const handleTomorrowClick = () => {
    const startDate = addDays(new Date(), 1);
    const endDate = addDays(startDate, 1);
    setState([{ startDate, endDate, key: "selection" }]);
    setCheckInDate(startDate);
    setCheckOutDate(endDate);
    setCheckInDate2(format(startDate, "MMMM/dd/yyyy"));
    setCheckOutDate2(format(endDate, "MMMM/dd/yyyy"));
  };

  const handleTonightClick = () => {
    const startDate = new Date();
    const endDate = addDays(startDate, 1);
    setState([{ startDate, endDate, key: "selection" }]);
    setCheckInDate(startDate);
    setCheckOutDate(endDate);
    setCheckInDate2(format(startDate, "MMMM/dd/yyyy"));
    setCheckOutDate2(format(endDate, "MMMM/dd/yyyy"));
  };

  const handleWeekendClick = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    const daysUntilFriday = (5 - dayOfWeek + 7) % 7;
    const nextFriday = addDays(today, daysUntilFriday);

    const endDate = addDays(nextFriday, 2);

    setState([{ startDate: nextFriday, endDate, key: "selection" }]);
    setCheckInDate(nextFriday);
    setCheckOutDate(endDate);
    setCheckInDate2(format(nextFriday, "MMMM/dd/yyyy"));
    setCheckOutDate2(format(endDate, "MMMM/dd/yyyy"));
  };
  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      console.log(
        format(checkInDate, "MMMM/dd/yyyy"),
        format(checkOutDate, "MMMM/dd/yyyy")
      );
      if (
        format(checkInDate, "MMMM/dd/yyyy") ===
        format(checkOutDate, "MMMM/dd/yyyy")
      ) {
        alert("Please select different dates");
        setCheckOutDate(null);
        setCheckInDate(null);
        if (location.pathname !== "/typeofstays") {
          setCheckOutDate2(null);
          setCheckInDate2(null);
        }
      }
    }
  }, [checkInDate, checkOutDate]);
  return (
    <div
      style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      className={`${
        location.pathname.includes("propertyDetails")
          ? "pb-4"
          : "py-4 max-md:p-0"
      } ${
        location.pathname === "/luxuryproperties" && "max-w-[400px] p-3"
      } relative flex justify-center items-center flex-col lg:px-8 px-4 rounded-3xl max-md:rounded-lg bg-white max-lg:w-full w-[784px] z-40`}
    >
      <DateRangePicker
        ranges={state}
        onChange={(item) => {
          if (
            location.pathname === "/" ||
            location.pathname === "/ClientDairies" ||
            location.pathname === "/allproperties"
          ) {
            setCheckInDate2(format(item.selection.startDate, "MMMM/dd/yyyy"));
          }

          if (item.selection.startDate !== item.selection.endDate) {
            setCheckOutDate(item.selection.endDate);
            if (
              location.pathname === "/" ||
              location.pathname === "/ClientDairies" ||
              location.pathname === "/allproperties"
            ) {
              setCheckOutDate2(format(item.selection.endDate, "MMMM/dd/yyyy"));
            }
          } else {
            setCheckOutDate(null);
          }

          setCheckInDate(item.selection.startDate);
          setState([item.selection]);
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={monthShow}
        minDate={new Date()}
        direction="horizontal"
      />
      <div
        className={`${
          (location.pathname.includes("propertyDetails") ||
            location.pathname === "/luxuryproperties") &&
          "hidden"
        } min-h-[54px] flex-col justify-center items-start gap-4 max-md:px-3 pb-6 mt-6 inline-flex w-full`}
      >
        <div className="max-lg:w-full w-[704px] h-[0px] border border-gray-300"></div>
        <div className="flex max-md:flex-col max-md:gap-5 justify-between w-full">
          <div className="justify-start items-center gap-3 flex max-md:mx-4 overflow-scroll example">
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button
                  className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex"
                  onClick={handleFlexibleClick}
                >
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-sm font-medium leading-[14px] text-nowrap tracking-tight">
                      I am flexible
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button
                  className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex"
                  onClick={handleTomorrowClick}
                >
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-sm font-medium leading-[14px] tracking-tight">
                      Tomorrow
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button
                  className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex"
                  onClick={handleTonightClick}
                >
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-sm font-medium leading-[14px] tracking-tight">
                      Tonight
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button
                  className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex"
                  onClick={handleWeekendClick}
                >
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-nowrap text-sm font-medium leading-[14px] tracking-tight">
                      This weekend
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowDateRange(false)}
            className="justify-start max-lg:justify-end cursor-pointer items-center gap-2 inline-flex"
          >
            <div className="text-zinc-950 text-sm font-medium leading-[14px] tracking-tight">
              CLOSE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRange;
