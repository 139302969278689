import banner from "../../../assets/svg/AboutUsBanner.webp";
import Facebook from "../../../assets/svg/FacebookIcon.webp";
import Instagram from "../../../assets/svg/InstagramIcon.webp";
import Linkedin from "../../../assets/svg/LinkdinIcon.webp";
import Twitter from "../../../assets/svg/TwitterIcon.webp";
import UkiyoLogoOnly from "../../../assets/svg/UkiyoLogoOnly.webp";

const AboutUsBanner = ({ bannerHeading, bannerDescription }) => {
  return (
    <>
      <div className="sm:hidden">
        <div className="flex flex-col gap-3">
          <img
            src={UkiyoLogoOnly}
            className="mt-[13px] h-[50px] w-[89px] mx-auto"
            alt="Ukiyo Logo"
          />
          <div className="h-6 justify-start items-start gap-8 inline-flex mt-[16px] mx-auto pb-5 sm:pb-0">
            <a
              href="https://www.facebook.com/UkiyoStays/"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              className="w-6 h-6 relative"
            >
              <img src={Facebook} alt="Facebook Icon" />
            </a>
            <a
              href="https://www.instagram.com/ukiyostays/"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              className="w-6 h-6 relative"
            >
              <img src={Instagram} alt="Instagram Icon" />
            </a>
            <a
              href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A75879401&keywords=ukiyo%20stays"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              className="w-6 h-6 relative"
            >
              <img src={Linkedin} alt="LinkedIn Icon" />
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              className="w-6 h-6 relative"
            >
              <img src={Twitter} alt="Twitter Icon" />
            </a>
          </div>
        </div>

        {/* //banner div */}
        <div className="w-[100%] h-full">
          <img
            className="object-cover flex justify-end h-[80vh] w-full"
            src={banner}
            alt="About Us Banner"
          />
          <div className="absolute w-3/4 bg-slate-900/80 flex flex-col justify-center items-start top-[27.5%] right-[50px] h-[79.5%] bottom-0 sm:w-[416px] text-white px-8 text-center">
            <div className="sm:max-w-[352px] w-full text-white text-base font-normal text-start leading-7">
              {bannerDescription}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-[144px] hidden sm:block">
        <div className="relative flex h-[602px]">
          <div className="flex-grow w-[23%] h-full bg-white ps-12">
            <img
              src={UkiyoLogoOnly}
              className="mt-[63px] h-[20%]"
              alt="Ukiyo Logo"
            />
            <div className="text-zinc-950 text-5xl font-medium font-butler mt-8 leading-[57.60px] w-52 h-52">
              {bannerHeading}
            </div>
            <div className="h-6 justify-start items-start gap-8 inline-flex mt-[276px]">
              <a
                href="https://www.facebook.com/UkiyoStays/"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                className="w-6 h-6 relative"
              >
                <img src={Facebook} alt="Facebook Icon" />
              </a>
              <a
                href="https://www.instagram.com/ukiyostays/"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                className="w-6 h-6 relative"
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
              <a
                href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A75879401&keywords=ukiyo%20stays"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                className="w-6 h-6 relative"
              >
                <img src={Linkedin} alt="LinkedIn Icon" />
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                className="w-6 h-6 relative"
              >
                <img src={Twitter} alt="Twitter Icon" />
              </a>
            </div>
          </div>

          <div className="w-[77%] relative h-full">
            <img
              className="object-cover flex justify-end h-full w-full"
              src={banner}
              alt="About Us Banner"
            />
            <div className="absolute inset-0 bg-black opacity-30"></div>
            <div className="absolute w-full bg-slate-900/80 flex flex-col justify-center items-start top-[0%] right-[120px] h-full bottom-0 sm:w-[416px] text-white px-8 pb-8 text-center">
              <div className="w-[60px] h-px bg-white mt-[315px] mb-6"></div>
              <div className="max-w-[352px] text-white text-base font-normal text-start leading-7">
                {bannerDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsBanner;
